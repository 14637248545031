import React from 'react';
import './off-page-seo.css';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Footer from '../components/footer';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';

const Backlinks = `${process.env.PUBLIC_URL}/images/off-page-seo/high-authority-backlinks.png`;
const Blogging = `${process.env.PUBLIC_URL}/images/off-page-seo/guest-blogging-opportunities.png`;
const LocalDirectory = `${process.env.PUBLIC_URL}/images/off-page-seo/local-directory-submissions.png`;
const Bookmarking = `${process.env.PUBLIC_URL}/images/off-page-seo/social-bookmarking.png`;
const Influencer = `${process.env.PUBLIC_URL}/images/off-page-seo/influencer-outreach.png`;
const Promotion = `${process.env.PUBLIC_URL}/images/off-page-seo/content-promotion.png`;
const Audit = `${process.env.PUBLIC_URL}/images/off-page-seo/comprehensive-website-audit.png`;

const Keyword = `${process.env.PUBLIC_URL}/images/on-page-seo/keyword-research-and-implementation.png`;
const Engaging = `${process.env.PUBLIC_URL}/images/on-page-seo/smart-high-quality-content.png`;
const Enhancements = `${process.env.PUBLIC_URL}/images/on-page-seo/on-page-seo-analysis.png`;
const Strategies = `${process.env.PUBLIC_URL}/images/on-page-seo/customized-strategies-for-business-goals.png`;
const Tracking = `${process.env.PUBLIC_URL}/images/on-page-seo/expertise-in-on-page-seo.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'Off-Page SEO: Professional Link Building',
    description: "Drive organic traffic and improve your website’s authority with our bespoke link-building services. From high-quality backlinks to local citations, we ensure your site gains the trust and credibility it deserves in the eyes of search engines."
}

const Productivity = [
    {
      image: Backlinks,
      heading: 'High Authority Backlinks',
      para: "We secure backlinks from authoritative domains in your industry, improving your site's domain authority and trustworthiness.",
    },
    {
      image: Blogging,
      heading: 'Guest Blogging Opportunities',
      para: 'Our experts create compelling guest posts that are published on reputable blogs, driving referral traffic and boosting your backlink profile.',
    },
    {
      image: LocalDirectory,
      heading: 'Local Directory Submissions',
      para: 'We enhance local visibility by submitting your business to relevant directories, ensuring consistency across the web.',
    },
    {
        image: Bookmarking,
        heading: 'Social Bookmarking',
        para: 'Our team promotes your content on popular social bookmarking sites to improve indexing and reach a broader audience.',
      },
      {
        image: Influencer,
        heading: 'Influencer Outreach',
        para: "We collaborate with industry influencers to amplify your brand and secure valuable backlinks through authentic relationships.",
      },
      {
        image: Promotion,
        heading: 'Content Promotion Campaigns',
        para: "Our campaigns boost the visibility of your content across multiple platforms, attracting natural backlinks and brand mentions.",
      }
  ]

  const Optimization = [
    {
      image: Backlinks,
      heading: 'High Authority Backlinks',
      para: "We acquire backlinks from reputable websites to increase your domain authority, enhance search visibility, and strengthen your site's credibility.",
    },
    {
      image: Blogging,
      heading: "Guest Blogging Opportunities",
      para: 'Our team creates high-quality guest posts for publication on popular blogs, generating referral traffic and authoritative backlinks for your website.',
    },
    {
      image: Bookmarking,
      heading: 'Social Media & Bookmarking',
      para: "We amplify your content through social media platforms and bookmarking sites, enhancing visibility and driving organic traffic to your website.",
    },
    {
      image: Influencer,
      heading: 'Influencer Outreach',
      para: "We collaborate with niche influencers to secure authentic mentions and backlinks, boosting your brand visibility and authority in targeted markets.",
    },
    {
      image: Promotion,
      heading: 'Content Promotion Campaigns',
      para: "Through targeted promotion, we ensure your content reaches the right audience, encouraging natural backlinks and increased engagement.",
    },
    {
      image: Audit,
      heading: 'Local Business Listings',
      para: "We enhance your local search visibility by ensuring accurate, consistent information on high-quality local directories and citation sites.",
    },
  ]

  const seoSpecialists = [
    {
      image: Strategies,
      heading: 'Customized SEO Strategies',
      para: 'Our specialists tailor SEO plans to align with your business objectives, industry, and target audience, ensuring maximum impact.',
    },
    {
      image: Keyword,
      heading: 'Comprehensive Keyword Research',
      para: 'We identify high-performing keywords to optimize your content and improve search engine rankings for relevant searches.',
    },
    {
      image: Enhancements,
      heading: 'Technical SEO Expertise',
      para: "From improving site speed and fixing crawl errors to implementing schema markup, we address all technical aspects to enhance your website's performance.",
    },
    {
      image: Engaging,
      heading: 'Content Optimization',
      para: 'Our team ensures your content is engaging, keyword-rich, and aligned with search intent, driving better rankings and audience engagement.',
    },
    {
      image: Tracking,
      heading: 'Performance Tracking & Reporting',
      para: 'We provide regular updates and insights, tracking the success of your campaigns and adjusting strategies for optimal results.',
    },
    {
      image: Audit,
      heading: 'Proven Track Record of Success',
      para: 'With a history of delivering exceptional results across industries, our SEO specialists are equipped to help your business grow online.',
    },
  ]

function OffPageSeo() {
  return (
    <>
<Helmet>
    <title>Off-Page SEO Services | SEO Development | Stamens Software - Boost Your Website Rankings</title>
    <meta name="description" content="Enhance your website’s visibility with Stamens Software’s off-page SEO services. Our expert strategies include link building, social media optimization, and content marketing to improve your site’s authority and rankings on search engines." />
    <meta name="keywords" content="Off-Page SEO Services, SEO Development, Link Building, Social Media Optimization, Content Marketing, SEO Strategies, Improve Website Rankings, SEO Services, Stamens Software" />
</Helmet>

<Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

<section className="developer">
            <div className="container">
                <h2 className="head text-center mb-5">Why Choose Stamens for Off Page Optimization?</h2>
                <div className="row justify-content-center gy-4">
                {Productivity.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}            
                </div>
            </div>
        </section>

    <section className="growth">
        <div className="container">
            <h2 className="head text-center">
            Our Approach to Off-Page SEO Optimization
            </h2>
            <p className="text-center fs-5 pb-4">Our Off-Page SEO strategies are designed to increase your website's domain authority, improve search rankings, and build brand recognition. From authoritative backlinks and strategic partnerships to content promotions and social outreach, we implement proven techniques to enhance your online presence and deliver measurable results.</p>
            <div className="row justify-content-center gy-4">
            {Optimization.map((card, index) => (
           <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={"bg-white shadow rounded p-4 h-100"} h4={'h4'} childClassA={'h4'}/>
        ))}     
            </div>
        </div>
    </section>

    <section className="hire-dev">
            <div className="container">
                <h3 className="head text-center pb-4">
                    Hire Highly Skilled SEO Specialists
                </h3>
                <p className="text-center fs-5 pb-4">Elevate your online presence and drive measurable results by partnering with highly skilled SEO specialists. Our experts bring years of experience and a data-driven approach to help your business succeed in the digital landscape.</p>
                <div className="row justify-content-center gy-4">
                {seoSpecialists.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}     
                </div>
            </div>
        </section>

    <LetsCode />

    <section className="our-company-offpageseo">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={clients} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>   

        <section className="app-mobile">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={appMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head">Partner with Our Client-Centered Team for Exceptional Off-Page SEO Solutions </h3>
                        <p>We specialize in delivering customized Off-Page SEO services designed to boost your website’s authority and search engine rankings. Our expert team uses cutting-edge strategies to build high-quality backlinks, increase brand visibility, and drive organic traffic to your site. Our Off-Page SEO services include link building, social media promotion, influencer collaborations, and reputation management, all aimed at enhancing your website's online presence and credibility. With our proven approach, we work to improve your website’s trustworthiness and visibility in search engine results, driving long-term success for your business.</p>
                    </div>
                </div>
            </div>
        </section>

<Footer />
    </>
  )
}

export default OffPageSeo;
