import React from 'react';
import './ourteam.css';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Footer from '../components/footer';


const bannerDetails =
    {
        title: 'Our Team',
        subTitle: 'Stamens Software',
        description: 'We have a can-do attitude backed up by more than 10+ years experience delivering high-end, creative on time and on budget.'
    }

    const client = [
        `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
        `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
        `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
        `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
        `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
        `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
      ];

    const teamMembers = [
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
        { name: 'Rupak Rajput', profile: 'Web Developer', imgSrc: `${process.env.PUBLIC_URL}/images/team/member.png` },
    ];

function Ourteam() {
 
  return (
    <>
  <Helmet>
    <title>Our Team | Stamens Software - Experts in IT Solutions</title>
    <meta name="description" content="Meet the talented professionals behind Stamens Software. Our team of experienced developers, designers, and IT experts are committed to delivering cutting-edge technology solutions with excellence and innovation." />
    <meta name="keywords" content="Team, Stamens Software, IT Experts, Software Development, Web Designers, Technology Professionals, IT Solutions, Team of Professionals, Meet the Team" />
</Helmet>

    <Banner details={bannerDetails} />
    <section className="all-members">
  <div className="container">
    {/* Define rows with different numbers of members */}
    {[
      4, 5, 4, 3, // Define the number of members for each row
    ].map((membersInRow, rowIndex) => (
      <div key={rowIndex} className="row justify-content-center">
        {/* Slice team members for each row */}
        {teamMembers
          .slice(rowIndex * membersInRow, rowIndex * membersInRow + membersInRow)
          .map((member, index) => (
            <div key={index} className="col-6 col-md-2">
              <div className="member">
                <img
                  src={member.imgSrc}
                  alt="all members image"
                  className="img-fluid"
                  width="259"
                  height="289"
                />
                <div className="details">
                  <p className="name">{member.name}</p>
                  <p className="profile">{member.profile}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    ))}
  </div>
</section>


    <section className="our-invester">
      <div className="container">
        <h2 className="head">Our Investors</h2>
        <p className="tagline col-md-9 mx-auto text-center">
          Get in touch with our skilled developers to begin receiving the best design and development services for your ultimate website
        </p>
        <div className="investers-logo">
          {client.map((src, index) => (
            <img 
              key={index} 
              src={src} 
              alt={`client-logo-${index}`} 
              className="img-fluid" 
              width="237" 
              height="103" 
            />
          ))}
        </div>
      </div>
    </section>


    <section className="work-with-us">
      <div className="container">
        <h3 className="head text-center">Work With Us</h3>
        <div className="col-md-8 mx-auto">
          <p className="text-center">
            Do you enjoy solving complicated problems? Would you rather do things than talk about them? Do you
            find the intersection between the CPG industry, retailers, and data interesting?
          </p>
          <p className="text-center">
            Join us in our mission to level the CPG manufacturer playing field and change how the industry uses
            data. We offer a fun and dynamic environment where people are valued, respected, and rewarded.
          </p>

          <p className="text-center">
            To apply to any of our open positions, please send your updated resume and cover letter to:
            <a href="mailto:careers@stamenssoftware.com" className="text-dark">
              careers@stamenssoftware.com
            </a>
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-9">
            <div className="center-part">
              <div className="content">
                <h4 className="head">Ready to get started?</h4>
                <p>Get in touch or create an account.</p>
              </div>
              <div className="btn-group">
                <a href="/contact">
                  <i className="fa fa-clipboard-question"></i> Ask a Query
                </a>
                <a href="tel:+911203500848">
                  <i className="fa fa-phone" aria-hidden="true"></i> Call Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default Ourteam;
