import React from 'react';
import './softwaresecurity.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

const AccessManagement = `${process.env.PUBLIC_URL}/images/software-security/privileged-access-management.png`;
const DnsFiltering = `${process.env.PUBLIC_URL}/images/software-security/dns-filtering.png`;
const PatchManagement = `${process.env.PUBLIC_URL}/images/software-security/patch-management.png`;

const AI = `${process.env.PUBLIC_URL}/images/software-security/artificial-intelligence.png`;
const Automated = `${process.env.PUBLIC_URL}/images/software-security/automated.png`;
const SecurityAndProtection = `${process.env.PUBLIC_URL}/images/software-security/security-and-protection.png`;

const ComplianceManagement = `${process.env.PUBLIC_URL}/images/software-security/cyber-compliance-management.png`;
const BusinessSolutions = `${process.env.PUBLIC_URL}/images/software-security/business-continuity-solutions.png`;

const ThirdPartyRiskManagement = `${process.env.PUBLIC_URL}/images/software-security/third-party-risk-management.png`;
const TailoredRiskManagement = `${process.env.PUBLIC_URL}/images/software-security/tailored-risk-management.png`;
const ContinuousSecurity = `${process.env.PUBLIC_URL}/images/software-security/continuous-security.png`;
const DataSecurity = `${process.env.PUBLIC_URL}/images/software-security/data-security.png`;
const CloudSecurity = `${process.env.PUBLIC_URL}/images/software-security/enhanced-cloud-cecurity.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const client = [
  `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
  `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
  `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
];


const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'SECURITY COMPLIANCE SOFTWARE DEVELOPMENT'
}

const IndustrySolutions = [
  {
    image: AccessManagement,
    heading: 'Privileged Access Management (PAM)',
    para: 'Simplify user permission management while enhancing endpoint security with streamlined governance.',
  },
  {
    image: DnsFiltering,
    heading: 'DNS, HTTP, and HTTPS Filtering',
    para: 'Leverage advanced machine learning algorithms to filter traffic and proactively block digital threats.',
  },
  {
    image: PatchManagement,
    heading: 'Patch Management',
    para: "Optimize software deployment, updates, and inventory management with a unified, compliance-driven platform.",
  }
]

const AiDrivenSolutions = [
  {
    image: AI,
    heading: 'Artificial Intelligence and Machine Learning for Adaptive Compliance',
    para: 'We design and deploy AI-driven predictive compliance tools to interpret regulatory changes, ensuring seamless policy updates and enhanced adaptability. Our solutions redefine threat detection and response, equipping organizations to confidently tackle evolving cyber risks.',
  },
  {
    image: Automated,
    heading: 'Automated Security and Compliance Solutions',
    para: 'AI-enhanced compliance software delivers real-time risk management, automatic policy updates, and streamlined reporting. With AI-driven tools, organizations can efficiently manage third-party risks, monitor compliance in real-time, and generate actionable insights, ensuring readiness in a dynamic regulatory landscape.',
  },
  {
    image: SecurityAndProtection,
    heading: 'AI-Powered Data Security and Protection',
    para: "Advanced encryption technologies fortified by AI dynamically adjust security protocols to match data sensitivity and regulatory demands. AI-driven behavioral analytics detect anomalies, identify unusual activity, trigger timely alerts, and proactively mitigate risks.",
  },
  {
    image: AI,
    heading: 'AI-Enhanced Risk Assessment and Management',
    para: "AI solutions empower organizations to evaluate and monitor the security posture of partners, vendors, and suppliers. Predictive analytics leverage historical data to forecast risks, while automated vulnerability assessments swiftly identify and address system and network weaknesses.",
  }
]

const SecurityCompliance = [
  {
    image: ComplianceManagement,
    heading: 'Cyber Compliance Management',
    para: 'Streamlined solutions for managing and monitoring compliance with diverse IT regulations and standards.',
  },
  {
    image: BusinessSolutions,
    heading: 'Business Continuity Solutions',
    para: 'Comprehensive tools for disaster recovery, risk assessments, and program management to ensure seamless business continuity.',
  },
  {
    image: ComplianceManagement,
    heading: 'Cyber Vendor Risk Management',
    para: "Software designed to identify, evaluate, mitigate, and monitor IT vendor risks, empowering organizations with data-driven decision-making.",
  }
]

const Firewall = [
  {
    image: ComplianceManagement,
    heading: 'Advanced Threat Protection (ATP) for Business Networks',
    para: 'Prevent Man-in-the-Middle (MitM) attacks with Stamens real-time monitoring, IP address/firewall blocking, and multi-protocol message decoding techniques for enhanced cybersecurity.',
  },
  {
    image: BusinessSolutions,
    heading: 'Protection Against DDoS, Ransomware, & Phishing',
    para: 'Defend your business from DDoS attacks, ransomware, phishing, and other security breaches, ensuring the safety of critical company data.',
  },
  {
    image: ComplianceManagement,
    heading: 'VLAN Hopping Attack Prevention',
    para: "Implement and configure VLAN trunks to prevent switch spoofing and double tagging, securing web traffic, network credentials, and sensitive employee information.",
  }
]

const ComprehensiveSupport = [
  {
    image: ComplianceManagement,
    heading: 'Extended Cybersecurity Compliance Assistance',
    para: 'As cybersecurity compliance evolves in response to new threats and regulations, stamens team ensures your digital assets remain secure and compliant with global standards. Our extended support services provide continuous monitoring and proactive solutions, ensuring ongoing security and performance after project completion.',
  },
  {
    image: ThirdPartyRiskManagement,
    heading: 'Third-Party Risk Management for Security',
    para: 'Stamens experts evaluate risks posed by third-party vendors and partners to protect your valuable data and intellectual property. We provide thorough security risk assessments to ensure your operations are secure and free from costly breaches.',
  },
  {
    image: TailoredRiskManagement,
    heading: 'Tailored Risk Management and Compliance Solutions',
    para: "Stamens customizes risk management and compliance software to meet your business's specific needs. Our developers create solutions that address industry-specific challenges, helping you proactively manage threats and safeguard assets.",
  },
  {
    image: ContinuousSecurity,
    heading: 'Continuous Security and Compliance Monitoring',
    para: "Even after project completion, stamens team continuously monitors your systems for security threats and ensures compliance standards are upheld. Our approach minimizes disruptions and ensures long-term security and compliance.",
  },
  {
    image: DataSecurity,
    heading: 'Data Security and Regulatory Compliance',
    para: "Stamens experts focus on maintaining the integrity and security of your data, protecting it from unauthorized access and ensuring compliance with data protection regulations. We ensure your network remains secure while meeting all industry standards.",
  },
  {
    image: CloudSecurity,
    heading: 'Enhanced Cloud Security Services',
    para: "Stamens strengthens cloud platforms with advanced security features to protect data, manage digital assets, and provide ongoing monitoring. Our continuous support ensures that your workflows remain secure and uninterrupted while utilizing the scalability and collaboration benefits of the cloud.",
  }
]

function SoftwareSecurity() {
  return (
    <>
   <Helmet>
   <meta name="description" content="Stamens Software, provides best software security services & solutions in Noida to protect your applications and data from cyber threats and vulnerabilities."/>
   <meta name="keywords" content="Software Security Services & Solutions, Software Security Services in Noida, Cybersecurity Services in Noida"/>
   <title>Software Security Services | Software Security Services & Solutions</title>
</Helmet>

    <Banner details={bannerDetails} descriptionClass={'banner-head text-center'}/>

    <section class="our-fetaures-security">
            <div class="container">
                <h2 class="head">Expert Security Compliance Developers for Industry-Specific Solutions</h2>
                <p class="tagline">Crafting tailored security and compliance software with years of expertise in healthcare, finance, and beyond.</p>
                <div class="row gy-4">
                {IndustrySolutions.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-4'} childClassA={'small-head'} />
            ))}  
                </div>
            </div>
        </section>

        <section class="work-process-security">
            <div class="container">
                <h3 class="head text-center">AI-Driven Cybersecurity Compliance Solutions</h3>
                <p class="tagline text-center">Harness the power of Artificial Intelligence to strengthen cybersecurity with advanced detection, prevention, and response capabilities. Automated AI processes ensure seamless adherence to evolving regulatory standards and requirements.</p>
                <div class="row gy-4">
                {AiDrivenSolutions.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))}  
                </div>
            </div>
        </section>

        <section class="our-fetaures-security">
            <div class="container">
                <h3 class="head">Security Compliance Software Development</h3>
                <p class="tagline">We create IT compliance software tailored to meet standards such as PCI, HIPAA, HITECH, GDPR, FISMA, ISO 27000, SEC, GLBA, FINRA, NYDFS, NIST 800-171, and more.</p>
                <div class="row gy-4">
                {SecurityCompliance.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-4'} childClassA={'small-head'} />
            ))} 
                </div>
            </div>
        </section>

        <LetsCode />

        <section class="our-fetaures-security">
            <div class="container">
                <h3 class="head">Advanced Firewall & Antivirus Protection for Business Networks</h3>
                <p class="tagline">Stamens delivers robust firewall and antivirus solutions designed to safeguard systems from malware, spyware, ransomware, viruses, and other cyber threats.</p>
                <div class="row gy-4">
                {Firewall.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-4'} childClassA={'small-head'} />
            ))} 
                </div>
            </div>
        </section>

        <section class="application-solutions-security">
            <div class="container">
                <h3 class="head">Comprehensive Support for Security Compliance Software</h3>
                <p class="tagline">Our dedicated support team actively monitors resources to eliminate threats and maintain optimal performance, offering a holistic approach that aligns with your project’s goals.</p>
                <div class="row gy-4">
                {ComprehensiveSupport.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'} />
            ))} 
                </div>
            </div>
        </section>

        <section class="our-company-security">
            <div class="container">
                <h4 class="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={industry15} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={sourceCode} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={microsoftAzure} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={response24} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={certifiedDevelopers} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={projectManager} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={clients} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={tightDeadlines} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>   

        <section class="app-mobile-security">
                    <div class="container">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-4">
                                <img src={appMobile} alt="app-mobile" class="img-fluid" />
                            </div>
                            <div class="col-md-8">
                                <h3 class="head"> Partner with our client-centered team for top-quality software solutions </h3>
                                <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                                <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                                <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>  
                                <Link to="/contact" class="hire-btn">Hire Us Now</Link>          
                            </div>
                        </div>
                    </div>
                </section>

                <section class="trusted-security">
            <div class="container">
                <div class="text-center">
                    <h4 class="head"><span>Trusted Companies</span> Trusted by many companies</h4>
                    <p class="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Our commitment to quality and performance has earned us the trust of top brands, empowering them to deliver exceptional user experiences and achieve their business goals. Join a network of successful companies that leverage our technology to enhance their app development processes.</p>
                </div>
                <Swiper
            modules={[Autoplay, Pagination]}
              className="mySwiper3"
              spaceBetween={30}
              slidesPerView={5}
              autoplay={{
                delay: 1000,
                disableOnInteraction: true,
              }}
            >
              {client.map((client, index) => (
                <SwiperSlide key={index} className='swiper-slide'>
                  <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
                </SwiperSlide>
              ))}
            </Swiper>
            </div>
        </section> 

    <Footer />
    </>
  )
}

export default SoftwareSecurity;
