import React from 'react'
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import ServiceComponent from '../components/servicecomponent';
import './productlifecycle.css';
 
import LetsCode from '../components/letscode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
 
const RequirementGathering = `${process.env.PUBLIC_URL}/images/product-lifecycle/requirement-gathering.png`;
const ArchitecturalDesign = `${process.env.PUBLIC_URL}/images/product-lifecycle/architectural-design.png`;
const DevelopmentCoding = `${process.env.PUBLIC_URL}/images/product-lifecycle/development-coding.png`;
const QualityAssurance = `${process.env.PUBLIC_URL}/images/product-lifecycle/quality-assurance.png`;
const InstallationDeployment = `${process.env.PUBLIC_URL}/images/product-lifecycle/installation-deployment.png`;
const MaintenanceSupport = `${process.env.PUBLIC_URL}/images/product-lifecycle/maintenance-support.png`;
 
const BomManagement = `${process.env.PUBLIC_URL}/images/product-lifecycle/bom-management.png`;
const ChangeManagement = `${process.env.PUBLIC_URL}/images/product-lifecycle/change-management.png`;
const ManufacturingProcess = `${process.env.PUBLIC_URL}/images/product-lifecycle/manufacturing-process.png`;
const ProductDataManagement = `${process.env.PUBLIC_URL}/images/product-lifecycle/product-data-management.png`;
const ProductVariability = `${process.env.PUBLIC_URL}/images/product-lifecycle/product-variability.png`;
const Mbse = `${process.env.PUBLIC_URL}/images/product-lifecycle/mbse.png`;
const ProjectCollaboration = `${process.env.PUBLIC_URL}/images/product-lifecycle/project-collaboration.png`;
const RequirementManagement = `${process.env.PUBLIC_URL}/images/product-lifecycle/requirements-management.png`;
const ServiceProcess = `${process.env.PUBLIC_URL}/images/product-lifecycle/service-process.png`;
 
const Oracle = `${process.env.PUBLIC_URL}/images/product-lifecycle/oracle-agile.png`;
const Autodesk = `${process.env.PUBLIC_URL}/images/product-lifecycle/autodesk-valut.png`;
const Siemens = `${process.env.PUBLIC_URL}/images/product-lifecycle/siemens-plm.png`;
const Sap = `${process.env.PUBLIC_URL}/images/product-lifecycle/sap-plm.png`;
const PTC = `${process.env.PUBLIC_URL}/images/product-lifecycle/ptc-windchill.png`;
const Arena = `${process.env.PUBLIC_URL}/images/product-lifecycle/arena-plm.png`;
 
const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;
 
const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const client = [
    `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
    `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
    `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
  ];
 
const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'PRODUCT LIFECYCLE MANAGEMENT SERVICES'
}
 
const Lifecycle =[
    {
        image: RequirementGathering,
        heading: 'Requirement Gathering and Analysis',
        para: 'Developers outline the project scope, defining and documenting requirements and feasibility in a comprehensive Software Requirement Specification (SRS) document.',
      },
      {
        image: ArchitecturalDesign,
        heading: 'Architectural Design',
        para: 'Designers create high-level (HLD) or low-level (LLD) designs, incorporating SRS details, feedback, and interface relationships to establish a robust software architecture.',
      },
      {
        image: DevelopmentCoding,
        heading: 'Development Coding',
        para: 'The development team constructs the software by coding in languages like Python or JavaScript, integrating custom modules, compilers, and interpreters as needed.',
      },
      {
        image: QualityAssurance,
        heading: 'Quality Assurance and Testing',
        para: 'The software undergoes rigorous testing where QA teams identify and resolve bugs, ensuring the system is stable and reliable.',
      },
      {
        image: InstallationDeployment,
        heading: 'Installation and Deployment',
        para: 'Expert developers and engineers make final adjustments and deploy the software to the production environment for release to users or marketplaces.',
      },
      {
        image: MaintenanceSupport,
        heading: 'Ongoing Maintenance and Support',
        para: 'Post-deployment, IT support professionals provide ongoing maintenance, updates, and enhancements to ensure consistent system performance.',
      }
]
 
const TailoredPLM =[
    {
        image: BomManagement,
        heading: 'Bill of Materials (BOM) Management',
        para: 'Streamline product structure management with digital associativity across supply chain, manufacturing, engineering, sales, and field service processes to ensure seamless collaboration and efficiency.',
      },
      {
        image: ChangeManagement,
        heading: 'Change & Configuration Management',
        para: 'Easily define and manage product changes and configurations, sharing real-time updates across the enterprise to enhance collaboration and minimize production delays.',
      },
      {
        image: ManufacturingProcess,
        heading: 'Manufacturing Process Management',
        para: 'Deliver tailored methodologies for manufacturing environments, ensuring seamless coordination between engineering and manufacturing workflows to build a more agile enterprise.',
      },
      {
        image: ProductDataManagement,
        heading: 'Product Data & Information Management',
        para: 'Centralize engineering models, drawings, and documents in a unified repository, enabling effective communication and collaboration among distributed teams.',
      },
      {
        image: ProductVariability,
        heading: 'Product Variability Management',
        para: 'Enable manufacturers to diversify products, managing BOMs, embedded software, and configurations from a single data source to meet unique customer requirements.',
      },
      {
        image: Mbse,
        heading: 'Model-Based Systems Engineering (MBSE)',
        para: 'Utilize advanced tools and methodologies for early visualization and simulation, improving workflows, productivity, and regulatory compliance through collaborative engineering.',
      },
      {
        image: ProjectCollaboration,
        heading: 'Project & Design Collaboration',
        para: 'Facilitate seamless collaboration among geographically dispersed teams, focusing on design outsourcing and efficient project management for better outcomes.',
      },
      {
        image: QualityAssurance,
        heading: 'Quality Management',
        para: 'Ensure superior product quality with automated FMEA, accurate RCA, and closed-loop CAPAs to meet or exceed industry standards.',
      },
      {
        image: RequirementManagement,
        heading: 'Requirements & Test Management',
        para: 'Achieve end-to-end traceability by defining, verifying, and validating product requirements to mitigate risks and ensure customer satisfaction.',
      },
      {
        image: ServiceProcess,
        heading: 'Service Process Management',
        para: 'Centralize service information with automatically updated parts lists, technical documentation, and CAD illustrations to enhance efficiency and support.',
      }
]
 
const PLMSoftware =[
    {
        image: Oracle,
        heading: 'Oracle Agile PLM Integration',
        para: 'Integrate Oracle Agile PLM to efficiently manage your product value chain and lifecycle, providing clear insights into product data and enhancing operational efficiency.',
      },
      {
        image: Autodesk,
        heading: 'Autodesk Vault Integration',
        para: 'Implement Autodesk Vault for seamless product lifecycle and data management, improving collaboration and ensuring efficient tracking of product data throughout its lifecycle.',
      },
      {
        image: Siemens,
        heading: 'Siemens PLM Integration',
        para: 'Integrate Siemens PLM to streamline lifecycle management across 2D, hybrid 2D/3D, and 3D design environments, reducing IT overhead and enhancing productivity.',
      },
      {
        image: Sap,
        heading: 'SAP PLM Integration',
        para: 'Seamlessly integrate SAP PLM to help organizations develop and deliver high-quality products, aligning with business objectives and driving growth and efficiency.',
      },
      {
        image: PTC,
        heading: 'PTC Windchill Implementation',
        para: 'Implement PTC Windchill to consolidate product data and multi-system information, providing a unified view to enhance product lifecycle management across the enterprise.',
      },
      {
        image: Arena,
        heading: 'Arena PLM Integration',
        para: 'Arena PLM enables companies to develop and deliver high-quality, innovative products quickly and affordably, enhancing collaboration and streamlining the product development process.',
      }
]
 
function Productmanagementlifecycle() {
  return (
    <>
    <Helmet>
   <meta name="description" content="Stamens Software, a leading IT company in Noida, offers Product Lifecycle Management services to streamline development, optimize efficiency, and ensure success."/>
   <meta name="keywords" content="Product Lifecycle Management Services in Noida, PLM Services in Noida"/>
   <title>Product Lifecycle Management Services | Stamens Software</title>
</Helmet>

 
    <Banner details={bannerDetails} descriptionClass={"banner-head text-center"}/>
 
    <section class="our-fetaures-lifecycle">
            <div class="container">
                <h2 class="head">End-to-End Product Lifecycle Management with Stamens</h2>
                <p class="tagline">Stamens provides comprehensive product lifecycle management (PLM) services tailored to the needs of small, mid-sized, and enterprise businesses. Our solutions seamlessly integrate people, processes, and data to optimize production and manufacturing workflows.</p>
                <div class="row gy-4">
                {Lifecycle.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-4'} childClassA={'small-head'}/>
            ))}    
                </div>
            </div>
        </section>
 
        <section class="work-process-lifecycle">
            <div class="container">
                <h3 class="head text-center">Stamens Tailored PLM Software Solutions</h3>
                <p class="tagline text-center">Stamens customized PLM software provides enhanced supply chain flexibility, robust business continuity, comprehensive data governance, end-to-end traceability, and exceptional quality and compliance standards. These solutions are seamlessly integrated with existing ERP systems for maximum efficiency.</p>
                <div class="row gy-4">
                {TailoredPLM.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'}/>
            ))}    
                </div>
            </div>
        </section>
 
        <LetsCode />
 
        <section class="application-solutions-lifecycle">
            <div class="container">
                <h3 class="head">PLM Software Integration Services</h3>
                <p class="tagline">Stamens skilled developers utilize cutting-edge technologies to integrate leading PLM software solutions with your current business systems, optimizing development, implementation, and deployment workflows for seamless operations.</p>
                <div class="row gy-4">
                {PLMSoftware.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-md-6'} childClassA={'small-head'}/>
            ))}
                </div>
            </div>
        </section>
 
        <section class="our-company-lifecycle">
            <div class="container">
                <h4 class="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={industry15} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={sourceCode} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={microsoftAzure} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={response24} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={certifiedDevelopers} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={projectManager} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={clients} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={tightDeadlines} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
 
        <section className="app-mobile-lifecycle">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={appMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p><p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="trusted-lifecycle">
            <div class="container">
                <div class="text-center">
                    <h4 class="head"><span>Trusted Companies</span> Trusted by many companies</h4>
                    <p class="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Our commitment to quality and performance has earned us the trust of top brands, empowering them to deliver exceptional user experiences and achieve their business goals. Join a network of successful companies that leverage our technology to enhance their app development processes.</p>
                </div>
                <Swiper
            modules={[Autoplay, Pagination]}
              className="mySwiper3"
              spaceBetween={30}
              slidesPerView={5}
              autoplay={{
                delay: 1000,
                disableOnInteraction: true,
              }}
            >
              {client.map((client, index) => (
                <SwiperSlide key={index} className='swiper-slide'>
                  <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
                </SwiperSlide>
              ))}
            </Swiper>
            </div>
        </section> 

 
    <Footer />
    </>
  )
}
 
export default Productmanagementlifecycle;