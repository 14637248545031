import React from 'react';
import './clienttestimonials.css';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Footer from '../components/footer';
import LetsCode from '../components/letscode';

const deg = `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`;
const cabi = `${process.env.PUBLIC_URL}/images/clients/cabi.png`;
const scadea = `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`;
const techolution = `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`;
const proIt = `${process.env.PUBLIC_URL}/images/clients/pro-it.png`;
const nextLoop = `${process.env.PUBLIC_URL}/images/clients/next-loop.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;

const bannerDetails =
    {
        title: 'Testimonials',
        subTitle: 'Our Clients Testimonials',
        description: "Our clients, ranging from small businesses to mid-sized organizations and even Fortune 5000 companies, trust us to drive their technology goals forward with tailored software solutions."
    }

    const clientReviews = [
        {
            name: "Emily Davis",
            company: "BrightWave Agency",
            review: "Stamens Software's team exceeded our expectations! Their professionalism and technical skills stood out, even when handling complex tasks. Despite numerous changes, they ensured everything was delivered perfectly. Highly recommend them!"
        },
        {
            name: "James Miller",
            company: "Global Logistics Corp",
            review: "Working with Stamens Software was seamless and productive. They provided innovative solutions for our unique needs and delivered on time without compromising quality. An excellent team to partner with."
        },
        {
            name: "Sophia Hernandez",
            company: "Greenfield Enterprises",
            review: "We’re extremely happy with Stamens Software. Their development team handled every challenge effectively. Their flexibility and commitment to deadlines make them a trustworthy partner for any business."
        },
        {
            name: "Ethan Wilson",
            company: "BlueHorizon Technologies",
            review: "The team at Stamens Software brought our vision to life. Their attention to detail and consistent communication made the project a success. Fantastic experience!"
        },
        {
            name: "Isabella Martinez",
            company: "FreshStart Solutions",
            review: "Choosing Stamens Software was the best decision we made for our project. Their professionalism and expertise ensured our system was delivered without any delays."
        },
        {
            name: "Liam Anderson",
            company: "NextGen Innovations",
            review: "Stamens Software's developers are outstanding. They transformed our ideas into a functional, user-friendly product. Excellent service and highly efficient."
        },
        {
            name: "Olivia Brown",
            company: "FutureGrowth Investments",
            review: "What sets Stamens Software apart is their ability to adapt and deliver under tight deadlines. Their commitment to quality is commendable."
        },
        {
            name: "Noah Johnson",
            company: "Visionary Media",
            review: "I’m thoroughly impressed with Stamens Software’s professionalism and expertise. They ensured our website redesign was flawless, on time, and within budget."
        },
        {
            name: "Ava Taylor",
            company: "GlobalHealth Tech",
            review: "The level of dedication Stamens Software’s team demonstrated throughout the project was remarkable. They went above and beyond to meet our expectations."
        },
        {
            name: "Benjamin Lee",
            company: "UrbanRetail Solutions",
            review: "From concept to delivery, Stamens Software has been a reliable partner. They brought innovative ideas to the table and executed them perfectly."
        },
        {
            name: "Mia White",
            company: "EcoNest Technologies",
            review: "Working with Stamens Software was effortless. Their ability to manage a large-scale project while maintaining transparency and efficiency was impressive."
        },
        {
            name: "Lucas Thompson",
            company: "HealthSphere Network",
            review: "Stamens Software’s developers are outstanding. They delivered a highly secure, scalable solution for our platform that exceeded all expectations."
        },
        {
            name: "Harper Garcia",
            company: "Aurora Marketing Agency",
            review: "We’re thrilled with the results from Stamens Software. They understood our needs perfectly and delivered a polished, high-quality product."
        },
        {
            name: "William Martin",
            company: "Infinity Solutions",
            review: "Stamens Software is a game-changer. Their developers possess unmatched expertise and ensured every feature was implemented flawlessly."
        },
        {
            name: "Ella Moore",
            company: "Zenith Logistics",
            review: "Our experience with Stamens Software was fantastic. They were responsive, innovative, and truly invested in delivering a successful outcome for our project."
        },
        {
            name: "Logan Walker",
            company: "GreenValley Tech",
            review: "Stamens Software stands out for their exceptional technical skills and dedication. They delivered a fully functional platform ahead of schedule!"
        },
        {
            name: "Charlotte Robinson",
            company: "DigitalWave Studios",
            review: "The team at Stamens Software was a pleasure to work with. Their expertise and hands-on approach ensured we achieved our business goals seamlessly."
        },
        {
            name: "Jack Harris",
            company: "NovaCloud Systems",
            review: "Stamens Software’s proactive approach and expertise were key to our project’s success. They delivered a robust, high-performing application that our users love."
        },
        {
            name: "Amelia Clark",
            company: "RapidGrowth Enterprises",
            review: "We trusted Stamens Software with our project, and they delivered exceptional results. Their professionalism, communication, and commitment to quality were top-notch."
        },
        {
            name: "Elijah Young",
            company: "PrimeTech Solutions",
            review: "Partnering with Stamens Software has been an incredible journey. Their ability to adapt to our requirements while delivering top-quality work is unparalleled."
        }
    ];


function ClientTestimonials() {
  return (
    <>
   <Helmet>
    <title>Client Testimonials | Stamens Software - Hear from Our Satisfied Clients</title>
    <meta name="description" content="Read authentic client testimonials for Stamens Software. Discover how our software, web development, and IT solutions have helped businesses grow and succeed with innovative, reliable services." />
    <meta name="keywords" content="Client Testimonials, Stamens Software, Customer Reviews, IT Services, Software Development, Web Development, Client Feedback, Business Success Stories" />
</Helmet>
<Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

<section class="our-clients-ct">
            <div class="container">
                <h2 class="head text-center mb-5">Our Clients</h2>
                <div class="clients-logos">
                    <img src={deg} alt="Client 1" class="img-fluid" height="103" width="237" />
                    <img src={scadea} alt="Client 4" class="img-fluid" height="103" width="237" />
                    <img src={techolution} alt="Client 5" class="img-fluid" height="103" width="237" />
                    <img src={nextLoop} alt="Client 6" class="img-fluid" height="103" width="237" />
                    <img src={cabi} alt="Client 2" class="img-fluid" height="103" width="237" />
                    <img src={proIt} alt="Client 3" class="img-fluid" height="103" width="237" />
                </div>
            </div>
        </section>

        <section class="clients-says">
            <div class="container">
                <h2 class="head text-center">
                    WHAT CLIENTS ARE SAYING
                </h2>
                <p class="text-center fs-5 pb-4">Wondering why countless businesses choose to partner with us? Here's what our clients have to share about their experiences.</p>
                <div id="client-reviews" class="reviews">
                {clientReviews.map((clientReview, index) => (
                     <div class="review-card" key={index}>
                        <p class='review'>{clientReview.review}</p>
                        <div class="name">
                            <span>{clientReview.name}</span>
                            <span>{clientReview.company}</span>
                        </div>
                     </div>
                 ))}     
                </div>
                <div class="text-center mt-4">
                    <a href="https://www.google.com/search?q=stamens+software+review+on+google&oq=stamens+software+review+on+google&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQIRigAdIBCTEwMTkyajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x390ce5ddf66d9991:0x8bd9694e9ba7ead8,3,,,," target="_blank" class="review-btn">Write a Review</a>
                </div>
            </div>
        </section>

        <LetsCode />

        <section class="our-company-ct">
            <div class="container">
                <h4 class="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={industry15} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={sourceCode} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={microsoftAzure} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={response24} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={certifiedDevelopers} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={projectManager} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={clients} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={tightDeadlines} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>   

        <section class="app-mobile">
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="col-md-4">
                        <img src={appMobile} alt="app-mobile" class="img-fluid" />
                    </div>
                    <div class="col-md-8">
                        <h3 class="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p><p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                        <a href="#" class="hire-btn">Hire Us Now</a>
                    </div>
                </div>
            </div>
        </section>

<Footer />
    </>
  )
}

export default ClientTestimonials;
