import React from 'react';
import './technicalseo.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';

const SeoAudit = `${process.env.PUBLIC_URL}/images/technical-seo/comprehensive-seo-audits.png`;
const WebsiteOptimization = `${process.env.PUBLIC_URL}/images/technical-seo/website-optimization.png`;
const CuttingEdgeSolutions = `${process.env.PUBLIC_URL}/images/technical-seo/cutting-edge-solutions.png`;

const CrawlingAndIndexing = `${process.env.PUBLIC_URL}/images/technical-seo/optimize-crawling-and-indexing.png`;
const WebsiteSpeed = `${process.env.PUBLIC_URL}/images/technical-seo/enhance-website-speed.png`;
const JavaScriptIssues = `${process.env.PUBLIC_URL}/images/technical-seo/resolve-javaScript-issues.png`;
const MobileFriendlyOptimization = `${process.env.PUBLIC_URL}/images/technical-seo/mobile-friendly-optimization.png`;
const FixTechnicalErrors = `${process.env.PUBLIC_URL}/images/technical-seo/fix-technical-errors.png`;
const StructuredDataImplementation = `${process.env.PUBLIC_URL}/images/technical-seo/structured-data-implementation.png`;

const Benchmarking = `${process.env.PUBLIC_URL}/images/technical-seo/industry-competitor-benchmarking.png`;
const RecoveryFromPenalties = `${process.env.PUBLIC_URL}/images/technical-seo/recovery-from-penalties.png`;

const Understand = `${process.env.PUBLIC_URL}/images/technical-seo/understand.png`;
const QualityContent = `${process.env.PUBLIC_URL}/images/technical-seo/smart-high-quality-content.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;


const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'Technical SEO Services from Stamens',
    description: "Ensure your site is crawled, indexed, and ranks well on search engine results with a seamless user experience. A successful website requires fast loading times, a solid site structure, and robust code that performs reliably. If your site's performance is falling short, our expert team is here to assist."
}

const Productivity = [
    {
      image: SeoAudit,
      heading: 'Comprehensive SEO Audits',
      para: "Unlock your website’s potential with detailed technical SEO audits. We analyze site speed, crawlability, indexation, and more to identify and resolve issues that impact your rankings.",
    },
    {
      image: WebsiteOptimization,
      heading: 'Website Optimization',
      para: 'Enhance your site’s performance with our tailored optimization strategies. From fixing broken links to improving mobile usability, we ensure your site meets all technical requirements for better visibility.',
    },
    {
      image: CuttingEdgeSolutions,
      heading: 'Cutting-Edge Solutions',
      para: 'Leverage advanced tools and techniques to stay ahead in the digital race. From structured data implementation to site migration support, our team ensures seamless technical excellence.',
    }
  ]

  const Optimization = [
    {
      image: CrawlingAndIndexing,
      heading: 'Optimize Crawling and Indexing',
      para: "Ensure search engines can crawl and index your site seamlessly. We tackle technical issues that might be blocking your pages from getting ranked.",
    },
    {
      image: WebsiteSpeed,
      heading: "Enhance Website Speed",
      para: 'Improve your site’s loading speed to meet Google’s standards and provide a better user experience, boosting your SEO performance.',
    },
    {
      image: JavaScriptIssues,
      heading: 'Resolve JavaScript Issues',
      para: "Specialized in JavaScript SEO, we help ensure that JavaScript-heavy websites are fully crawlable and indexable by search engines.",
    },
    {
      image: MobileFriendlyOptimization,
      heading: 'Mobile-Friendly Optimization',
      para: "With mobile-first indexing, we ensure your website is fully optimized for all devices, offering smooth navigation and quick load times.",
    },
    {
      image: FixTechnicalErrors,
      heading: 'Fix Technical Errors',
      para: "From broken links to 404 errors and improper redirects, we identify and resolve all technical issues that might be hindering your SEO.",
    },
    {
      image: StructuredDataImplementation,
      heading: 'Structured Data Implementation',
      para: "Enhance your search visibility by adding structured data to your site, helping Google better understand your content and display rich results.",
    },
  ]

  const seoSpecialists = [
    {
      image: Benchmarking,
      heading: 'Industry Competitor Benchmarking',
      para: "We evaluate your competitors' strengths and weaknesses, crafting strategies to highlight your unique value and secure a competitive edge in the market.",
    },
    {
      image: WebsiteSpeed,
      heading: 'Enhanced Page Load Performance',
      para: 'Boost your site’s load speed through optimized images, minimized code, and server enhancements, ensuring a fast and smooth browsing experience for users.',
    },
    {
      image: RecoveryFromPenalties,
      heading: 'Recovery from Penalties',
      para: "We identify the root causes of penalties, take corrective actions, and restore your rankings while implementing measures to enhance your website’s performance.",
    },
    {
      image: JavaScriptIssues,
      heading: 'Seamless Website Migration',
      para: 'Ensure a smooth transition during domain migration with our services, which include updating links, managing duplicate content, and handling 404 errors effectively.',
    },
    {
      image: SeoAudit,
      heading: 'Detailed Backlink Analysis',
      para: 'We review your backlink profile, remove harmful or low-quality links, and strengthen your domain authority to improve search engine rankings.',
    },
    {
      image: Benchmarking,
      heading: 'Resolving Broken Links',
      para: "Fixing broken links and addressing orphan pages ensures a smoother user experience, enhancing your website's credibility and usability.",
    },
  ]

  const Process = [
    {
      image: Understand,
      heading: 'Understand',
      para: "We start by gaining insights into your business, services, and target audience to align with your goals effectively.",
    },
    {
      image: SeoAudit,
      heading: 'Evaluate',
      para: "We perform a detailed audit of your website to identify its technical strengths, weaknesses, and areas needing improvement.",
    },
    {
      image: WebsiteSpeed,
      heading: 'Plan',
      para: "We develop a tailored strategy to address the unique challenges and opportunities of your website’s technical health.",
    },
    {
      image: CuttingEdgeSolutions,
      heading: 'Execute',
      para: 'Our team implements the approved solutions efficiently, ensuring seamless improvements across the website.',
    },
    {
      image: WebsiteOptimization,
      heading: 'Monitor',
      para: 'We continuously track the website’s performance to measure the impact of implemented changes and optimize further.',
    },
    {
      image: QualityContent,
      heading: 'Optimize',
      para: "Our detailed performance reports guide ongoing improvements, helping your business thrive in the digital landscape.",
    },
  ]

function TechnicalSeo() {
  return (
    <>
   <Helmet>
    <title>Technical SEO Services | SEO Development | Stamens Software - Optimize Your Website’s Performance</title>
    <meta name="description" content="Stamens Software offers expert technical SEO services to enhance your website’s performance. We optimize site speed, improve mobile usability, fix crawl errors, and ensure your website is search engine-friendly." />
    <meta name="keywords" content="Technical SEO Services, SEO Development, Website Optimization, Site Speed, Mobile Usability, Crawl Errors, SEO Strategies, SEO Services, Stamens Software" />
</Helmet>


<Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

<section className="developer">
            <div className="container">
                <h2 className="head text-center mb-5">Expert Technical SEO Solutions from Stamens Software Private Limited</h2>
                <div className="row justify-content-center gy-4">
                {Productivity.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}            
                </div>
            </div>
        </section>
        
    <section className="growth">
        <div className="container">
            <h2 className="head text-center">
            Technical SEO Services: Everything You Should Know
            </h2>
            <p className="text-center fs-5 pb-4">Technical SEO focuses on optimizing your website to ensure that search engines like Google can effectively crawl, index, and rank your pages on search engine result pages (SERPs). Making your content easily accessible and understandable for Google is crucial. However, if your site is built on JavaScript, you’re likely aware of the challenges in getting your pages properly crawled and ranked. If you’ve landed on this page, it’s likely because you’re searching for technical SEO services. This indicates your website might not be performing at its full potential, and we’re here to help you fix that.</p>
            <div className="row justify-content-center gy-4">
            {Optimization.map((card, index) => (
           <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={"bg-white shadow rounded p-4 h-100"} h4={'h4'} childClassA={'h4'}/>
        ))}     
            </div>
        </div>
    </section>

    <section className="hire-dev">
            <div className="container">
                <h3 className="head text-center pb-4">
                  Technical SEO Services: What We Provide
                </h3>
                <p className="text-center fs-5 pb-4">Discover the range of technical SEO solutions we offer to help our clients optimize their websites for better performance and rankings.</p>
                <div className="row justify-content-center gy-4">
                {seoSpecialists.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={'shadow rounded p-4 h-100'} h4={'h4'} childClassA={'h4'}/>
            ))}     
                </div>
            </div>
        </section>

    <LetsCode />

    <section className="growth">
        <div className="container">
            <h2 className="head text-center">
            Our Process
            </h2>
            <p className="text-center fs-5 pb-4">Here’s how we devise the right strategy for each brand we work with.</p>
            <div className="row justify-content-center gy-4">
            {Process.map((card, index) => (
           <ServiceComponent  key={index} serviceprops={card} parentClass={'col-lg-4 col-md-6'} parentChildClass={"bg-white shadow rounded p-4 h-100"} h4={'h4'} childClassA={'h4'}/>
        ))}     
            </div>
        </div>
    </section>

    <section className="our-company-technicalseo">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={clients} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>   

        <section className="app-mobile">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={appMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head">Our Technical SEO Expertise for Enhanced Website Performance and Search Engine Visibility</h3>
                        <p>Our team specializes in providing comprehensive Technical SEO services tailored to enhance your website’s infrastructure and improve its rankings on search engines. By optimizing key on-page and off-page factors, we ensure that your site is fully optimized for search engine crawlers and users.</p>
                    </div>
                </div>
            </div>
        </section>

<Footer />
    </>
  )
}

export default TechnicalSeo;
