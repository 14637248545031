import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import Banner from '../components/banner';
import './technicalsupport.css';
import ServiceComponent from '../components/servicecomponent';
import { Link } from 'react-router-dom';
import LetsCode from '../components/letscode';
import CardComponent from '../components/cardComponent';

const talentPool = `${process.env.PUBLIC_URL}/images/talent-pool.png`;
const deticatedStaff = `${process.env.PUBLIC_URL}/images/Dedicated-Staff.png`;
const costSaving = `${process.env.PUBLIC_URL}/images/cost-save.png`;
const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;
const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;

const bannerDetails =
    {
        title: 'Our Services',
        subTitle: 'IT Technical Support',
        description: 'Support Plus provides faster issue resolution, protects your investment, and prevents IT issues before they become problems.'
    }

    const serviceCard = [
      {
          heading: 'IT Support Solutions',
          para: "Your business relies on the effectiveness of your tech platforms. Virtual Employee’s dedicated Indian tech support engineers offer 24×7 IT support, including a dedicated IT help desk to ensure that your business never faces any downtime.",
      },
      {
          heading: 'Cloud Computing',
          para: 'Whether your company is a cloud-based firm or you’re trying to upgrade to cloud technology from a traditional system, VE’s technical support specialists help you to do it effectively. They are adept at the use of cloud applications.',
      },
      {
          heading: 'Troubleshooting Support',
          para: 'Machines can malfunction. That’s just the nature of the beast. However, outsourcing troubleshooting support services with Virtual Employee in India helps you to fix your tech problems effectively and without experiencing significant downtime.',
      },
      {
          heading: 'Technical Consulting',
          para: "Have an outdated tech platform that needs updates or have a legacy infrastructure that is not keeping pace with your expansion? VE’s dedicated Indian tech support engineers are perfect for such tasks with more than 1,100 jobs under their belt.",
      },
      {
          heading: 'Network Monitoring',
          para: "Tech needs at a modern firm require constant monitoring to ensure they run smoothly. VE’s remote IT experts in India ensure your network is in perfect shape always. Our experts help you monitor networking components like routers, firewalls, servers and VMs.",
      },
      {
          heading: 'Network Security Solutions',
          para: 'Any network needs to be robust so as to fend off any attacks. Further, the ever evolving rules and regulations about customer data protection makes it imperative for firms to have a robust network security. VE’s IT support staff are perfect for the role.',
      },
      {
        heading: 'SQL Server Consulting',
        para: "Virtual Employee’s team of SQL Server consultants implement best practices throughout the development life cycle of your technology proprietary or not to ensure that your SQL server handles all kind of queries without failing.",
    },
    {
        heading: 'Data Storage Solutions',
        para: "Data is the new oil. Your business—whether data forms the core of its business or not—cannot do without storage and effective management of data. Hire VE’s online tech support staff in India for effective data solutions.",
    },
    {
        heading: 'Data Backup & Recovery',
        para: 'Regardless of the nature of your business, your data is vital. Hire technical support engineers from VE in India to create a bullet-proof backup system for data recovery, backup troubleshooting, backup management and other such data related issues.',
    },
  ]

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const client = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

  const technicalSupportService = [
    {
        image: talentPool,
        title: 'Largest Talent Pool',
        description: '3 million engineers graduate every year making India the home of IT support outsourcing in the world.',
    },
    {
        image: deticatedStaff,
        title: 'Dedicated 24×7 Staff',
        description: 'VE’s remote IT support engineers are our full-time employees which gives you the assurance of a full-time staffer.',
    },
    {
        image: costSaving,
        title: 'Massive Cost Savings',
        description: 'Online IT support experts command a significant remuneration locally. Outsourcing to VE helps you save as much as up to 70% in costs.',
    }
  ];

function TechnicalDSupport() {
  return (
    <>

<Helmet>
   <meta name="description" content="Stamens Software, a leading IT company in Noida, offers reliable IT and Technical IT Support services to ensure seamless operations and enhanced system performance."/>
   <meta name="keywords" content="Technical IT Support, IT Support Services in Noida, Technical Support Services in Noida"/>
   <title>Technical IT Support | Technical Support Stamens Software</title>
</Helmet>

    <Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

    <section className="cloud-services bg-white">
            <div className="container">
                <h2 className="head text-center">
                    IT Technical Support Services
                </h2>
                <p className="text-center fs-5 pb-4">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
                <div className="center-sec">
                    <div className="row justify-content-center gy-4">
                    {serviceCard.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} parentClass={"col-lg-4 col-md-6"}/>
            ))}
                    </div>
                </div>
            </div>
        </section>

        <section className="technologies-technicalsupport">
            <div className="container">
                <h3 className="head text-center">
                    Tech Support solutions to simplify the complexity that comes with technology.
                </h3>
                <p className="text-center fs-5 pb-4">With technologies like IoT and AI transforming lifestyles, Maxicus’ integrated Tech Support Services allow for an easy transition into digital life. With our pool of specialists 24*7 at the customer’s service, Maxicus ensures that customers receive the best return from new technology with our tech support solutions. Right from installation to after-care, with intelligent technology, knowledge base and other support tools, we are the right team to outsource Tech Support to.</p>

                <div className="text-center">
                    <Link to="/contact" className="requirements">Get in Touch</Link>
                </div>
                
            </div>
        </section>

        <LetsCode />

        <section className="process-technicalsupport">
            <div className="container">
                <h3 className="head text-center">
                    Why Outsource IT Support Services to India
                </h3>
                <p className="text-center fs-5 pb-4">We have a tried-and-tested process in place for developing time-efficient MVPs tailored to your specific needs. You can trust us to deliver results that exceed your expectations. Let’s have a look at our MVP development process.</p>
                
                <div className="row justify-content-center gy-4">
                    {/* <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <img src="assets/images/talent-pool.png" alt="talent" className="img-fluid" />
                            <h4 className="head">Largest Talent Pool</h4>
                            <p>3 million engineers graduate every year making India the home of IT support outsourcing in the world.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <img src="assets/images/Dedicated-Staff.png" alt="talent" className="img-fluid" />
                            <h4 className="head">Dedicated 24×7 Staff</h4>
                            <p>VE’s remote IT support engineers are our full-time employees which gives you the assurance of a full-time staffer.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <img src="assets/images/cost-save.png" alt="talent" className="img-fluid" />
                            <h4 className="head">Massive Cost Savings</h4>
                            <p>Online IT support experts command a significant remuneration locally. Outsourcing to VE helps you save as much as up to 70% in costs.</p>
                        </div>
                    </div> */}

                    {technicalSupportService.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentChildClass={'part'} childClassA={null} childClassB={null}/>
            ))}

                </div>

            </div>
        </section>

        <section className="partner bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pt-4">
                            <img src={appMobile} alt="mvp development" className="img-fluid" />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p className="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p className="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <a href="#" className="hire-btn">Hire Us Now</a>
                        </div>
                    </div>
                </div>
        </section>

        <section className="our-company-technicalsupport">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery
                                to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs
                                Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project
                                Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={client} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK,
                                Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight
                                Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    <Footer />

    </>
  )
}

export default TechnicalDSupport;
