import React from 'react';
import './innerServices.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import LetsCode from '../components/letscode';
import CardComponent from '../components/cardComponent';
const missionImage = `${process.env.PUBLIC_URL}/images/misson.png`;
const visionImage = `${process.env.PUBLIC_URL}/images/vision.png`;
const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;
const aspImage = `${process.env.PUBLIC_URL}/images/icons/asp.svg`;
const nodeImage = `${process.env.PUBLIC_URL}/images/icons/node.svg`;
const angularImage = `${process.env.PUBLIC_URL}/images/icons/angular.svg`;
const reactImage = `${process.env.PUBLIC_URL}/images/icons/react.svg`;
const pythonImage = `${process.env.PUBLIC_URL}/images/icons/python.svg`;
const javaImage = `${process.env.PUBLIC_URL}/images/icons/java.svg`;
const phpImage = `${process.env.PUBLIC_URL}/images/icons/php.svg`;
const htmlImage = `${process.env.PUBLIC_URL}/images/icons/html.svg`;
const vueImage = `${process.env.PUBLIC_URL}/images/icons/vue.svg`;
const partner = `${process.env.PUBLIC_URL}/images/partner.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const clients = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const WhyUs = [
    {
        image: customSoftwareDevelopment,
        title: 'Solutions',
        description: 'We have been providing cutting-edge and customized software solutions since our founding in 2012. We have assisted 130+ clients across the globe in streamlining their operations through our expert software product development services.'
      },
      {
        image: customSoftwareDevelopment,
        title: 'Knowledge and Expertise',
        description: 'Our team has extensive knowledge of various industries, allowing us to create industry-specific software applications that bring significant value to your organization.'
      },
      {
        image: customSoftwareDevelopment,
        title: 'Customized Solutions',
        description: 'We specialize in developing custom software solutions for businesses of all sizes. Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.'
      },
      {
        image: customSoftwareDevelopment,
        title: 'Proactive Approach',
        description: 'We understand your requirement first and then provide you best solution to achieve your unique business idea.'
      },
      {
        image: customSoftwareDevelopment,
        title: 'Experienced Team',
        description: 'Our team of developers are highly experienced in latest technologies and can help you achieve the maximum scalability by developing solution as per the latest industry standards and relevance.'
      },
      {
        image: customSoftwareDevelopment,
        title: '24/7 Support',
        description: 'Our team is always known for its availability and great customer support. Our team of highly professional developers is always available 24/7 to promptly address any queries.'
      },
]

const bannerDetails =
    {
        title: 'Our Services',
        subTitle: ' Custom Software Development',
        description: 'You might want to include how it started, any key milestones, and what sets you apart from others in your field.'
    }

function Customsoftwaredevelopment() {


  return (
    <>
    <Helmet>
    <meta name="description" content="Custom Software Development Servies in Noida - Stamens Software offering designing, development, and custom web development services. Call us today"/>
    <meta name="keywords" content="Custom Software Development Services, Custom Software Development Services in Noida. Custom Web Development Servies, Custom Web Development Servies in Noida"/>
    <title> Custom Software Development Services in Noida | Stamens Software</title>
    </Helmet>

    <Banner details={bannerDetails} />

        <section className="vision bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <h2 className="head">
                                <span>Our Vision</span>
                                Driving Innovation: Our Vision at Stamens
                            </h2>
                            <p className="mt-n2 mb-25">Our vision is to be a leading web development company recognized for transforming ideas into impactful digital experiences. We aspire to innovate continuously, setting industry standards that inspire businesses to thrive in the digital landscape.</p>
                            {/* <p className="mt-n2 mb-25">our mission is simple: to revolutionize the digital landscape by delivering top-notch technology solutions that drive growth, efficiency, and sustainability for our clients. We strive to be the trusted partner that businesses can rely on to navigate the complexities of the digital world.</p> */}
                        </div>
                        <div className="col-lg-5 pt-4">
                            <img src={visionImage} alt="vision" className="img-fluid rounded" />
                        </div>
                    </div>
                </div>
        </section>

        <section className="vision">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pb-4">
                            <img src={missionImage} alt="mission" className="img-fluid" />
                        </div>
                        <div className="col-lg-7">
                            <h3 className="head">
                                <span>Our Mission</span>
                                Innovating for Success: Our Technology Mission
                            </h3>
                            <p className="mt-n2 mb-25">Our mission is to empower businesses by delivering innovative and user-centric web development solutions that enhance their online presence. We strive to blend cutting-edge technology with creative design, ensuring that each website we build not only meets our clients' unique needs but also drives engagement and growth in an ever-evolving digital landscape.</p>
                            {/* <p className="mt-n2 mb-25">our mission is simple: to revolutionize the digital landscape by delivering top-notch technology solutions that drive growth, efficiency, and sustainability for our clients. We strive to be the trusted partner that businesses can rely on to navigate the complexities of the digital world.</p> */}
                        </div>
                    </div>
                </div>
        </section>

        <section className="vision bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <h3 className="head">
                                <span>Our Vision</span>
                                Driving Innovation: Our Vision at Stamens
                            </h3>
                            <p className="mt-n2 mb-25">Our vision is to be a leading web development company recognized for transforming ideas into impactful digital experiences. We aspire to innovate continuously, setting industry standards that inspire businesses to thrive in the digital landscape.</p>
                            {/* <p className="mt-n2 mb-25">our mission is simple: to revolutionize the digital landscape by delivering top-notch technology solutions that drive growth, efficiency, and sustainability for our clients. We strive to be the trusted partner that businesses can rely on to navigate the complexities of the digital world.</p> */}
                        </div>
                        <div className="col-lg-5 pt-4">
                            <img src={visionImage} alt="vision" className="img-fluid rounded" />
                        </div>
                    </div>
                </div>
        </section>

       <LetsCode />

        <section className="why-us">
            <div className="container">
                <h3 className="head">
                    <span>Why Us?</span>
                    Software is a great combination between artistry and engineering.
                </h3>
                <div className="row gy-4">
                {WhyUs.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentChildClass={'part'} childClassA={'icon'} childClassB={'content'} />
            ))}
                </div>
            </div>
        </section>

        <section className="technologie">
            <div className="container">
                <h3 className="head">
                    <span>Our Technologies</span>
                    All Technologies Software in stamens
                </h3>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={aspImage} alt="asp" className="img-fluid" />
                            <h4>.Net</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={nodeImage} alt="node" className="img-fluid" />
                            <h4>Node JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={angularImage} alt="angular" className="img-fluid" />
                            <h4>Angular JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={reactImage} alt="react" className="img-fluid" />
                            <h4>React JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={pythonImage} alt="python" className="img-fluid" />
                            <h4>Python</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={javaImage} alt="java" className="img-fluid" />
                            <h4>Java</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={phpImage} alt="php" className="img-fluid" />
                            <h4>PHP</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={vueImage} alt="vue" className="img-fluid" />
                            <h4>Vue JS</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="vision bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pt-4">
                            <img src={partner} alt="vision" className="img-fluid rounded" />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p className="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p className="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <a href="#" className="hire-btn">Hire Us Now</a>
                        </div>
                    </div>
                </div>
        </section>

        <section className="our-company-csd">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery
                                to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs
                                Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project
                                Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={clients} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK,
                                Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight
                                Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<Footer />
    
    </>
  )
}

export default Customsoftwaredevelopment;