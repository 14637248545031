import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import './callform.css';
import { apiRequest } from '../apiUtils';
import { Modal } from 'bootstrap';
const stamenslogo = `${process.env.PUBLIC_URL}/images/logo.svg`;

function Callform() {

    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: ''
      });

      const [errors, setErrors] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Message: '',
        captcha: ''
      });

      
      const URL = `https://devstamenssoftware-api-f5avbucde6cmajbj.southindia-01.azurewebsites.net/api/Email/SubmitEnquiry`

      const formRef = useRef();

      const [captcha, setCaptcha] = useState('');
      const [captchaInput, setCaptchaInput] = useState('');
      const [isLoading, setIsLoading] = useState(false); 
      const canvasRef = useRef(null);

      const generateCaptcha = () => {
        const characters = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptcha(result);
      };

      const drawCaptcha = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height); 
          ctx.fillStyle = '#f0f0f0';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
    
          ctx.font = '30px Arial';
          ctx.fillStyle = '#000000'; 

          for (let i = 0; i < captcha.length; i++) {
            ctx.save();
            ctx.translate(30 * i + 10, Math.random() * 10 + 20); 
            ctx.rotate(Math.random() * 0.2 - 0.1);
            ctx.fillText(captcha[i], 0, 0);
            ctx.restore();
          }
    
          for (let i = 0; i < 5; i++) {
            ctx.strokeStyle = '#cccccc';
            ctx.beginPath();
            ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.stroke();
          }
        }
      };
    
      useEffect(() => {
        generateCaptcha();

        const handleHide = () => {
          setFormData({
            Name: '',
            Email: '',
            Phone: '',
            Message: ''
          });
          setCaptchaInput('');
          setErrors({});
          generateCaptcha();
        };
    
        const modalElement = document.getElementById('exampleModal');
        if (modalElement) {
          modalElement.addEventListener('hidden.bs.modal', handleHide);
        }
    
        return () => {
          if (modalElement) {
            modalElement.removeEventListener('hidden.bs.modal', handleHide);
          }
        };

      }, []);

      useEffect(() => {
        drawCaptcha();
      }, [captcha]);

      const validateName = (name) => {
        const nameRegex = /^[A-Za-z\s'-]+$/;
        return nameRegex.test(name);
      };

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhone = (phone) => {
        const phoneRegex = /^\+?[\d\s\-()]{7,15}$/;
        return phoneRegex.test(phone);
      };


      const handleChange =(e)=>{
        const { name, value } = e.target;
        if(name === 'captcha'){
          setCaptchaInput(value);
        }else {
        setFormData(prevState =>({
            ...prevState,
            [name] : value
        }));
      }
        
      }

      const handleSubmit =async(e)=>{
        e.preventDefault();
        let valid = true;
        let newErrors = {}; 

        if(!formData.Name){
            valid = false;
            newErrors.Name = 'Name is required';
        }else if(!validateName(formData.Name)){
            valid = false;
            newErrors.Name = 'Only characters are allowed in the name!';
        }

        if(!formData.Email){
            valid = false;
            newErrors.Email = 'Email is required';
        }else if(!validateEmail(formData.Email)){
            valid = false;
            newErrors.Email = 'Not a valid mail';
        }
     
        if(!formData.Phone){
            valid = false;
            newErrors.Phone = 'Phone is required';
        }else if(!validatePhone(formData.Phone)){
            valid = false;
            newErrors.Phone = 'Not a number';
        }

        if(!captchaInput){
            valid = false;
            newErrors.captcha = 'Enter Captch';
          }else if(captcha !== captchaInput){
            valid = false;
            newErrors.captcha = 'CAPTCHA does not match';
            generateCaptcha();
          }

        if(valid){
          setIsLoading(true);
          try {
            const response = await apiRequest({ url: URL, data: formData, method: 'POST' });
            alert('Mail sent successfully');
            if(alert){
              setIsLoading(false);
              const closeButton = document.querySelector('.btn-close');
              closeButton.click();
            }
            setFormData({
              Name: '',
              Email: '',
              Phone: '',
              Message: '',
            });
            setCaptchaInput('');
          } catch (error) {
            alert('There was an error sending the mail.');
          } finally {
            setIsLoading(false); // Stop loading
          }
      }else{ 
          setErrors(newErrors);
          generateCaptcha();
      }
      }

  return (
  <>
 
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content popup-modal-details">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
              <div className="modal-body">
                  <div className="details">
                      <Link className="pb-2 ps-2 d-block" to="#"><img src={stamenslogo} alt="main logo" height="53" width="124" className="img-fluid" /></Link>
                      <div className="part">
                          <h3 className="heading"><i className="fa fa-envelope" aria-hidden="true"></i> Write to us</h3>
                          <Link to="mailto:contact@stamenssoftware.com">contact@stamenssoftware.com</Link>
                      </div>
                      <div className="part">
                          <h3 className="heading"><i className="fa fa-phone" aria-hidden="true"></i> Telephone</h3>
                          <Link to="tel:+911203500847" className="d-block">+91 120 3500 847</Link>
                          <Link to="tel:+911203500848" className="d-block">+91 120 3500 848</Link>
                      </div>
                      <div className="part">
                          <h3 className="heading"><i className="fa fa-location-dot" aria-hidden="true"></i> Location</h3>
                          <p>B-33, 2nd Floor, Sector 6, Noida, UP, India - 201301</p>
                      </div>
                  </div>
                  <div className="query-form">
                      <h2>Request Free Consultation</h2>
                      <p>Please fill the form below and one of our technical consultant will get in touch with you shortly</p>
                      
                      {isLoading ? (
                    <div className="loading-overlay">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p style={{color: '#000', fontWeight: 600, fontSize: '20px'}}>Submitting...</p>
                    </div>
                    ) : null}
                    
                      <form id="query-form" onSubmit={handleSubmit} ref={formRef} noValidate className={isLoading ? "blurred-form" : ""}>
                          <div className="mb-2">
                              <label htmlFor="name" className="form-label d-none">Name</label>
                              <input type="text" className="form-control" id="name" name="Name" placeholder="Name" value={formData.Name} onChange={handleChange}/>
                              {errors.Name && <small id="nameHelp" className="form-text text-danger">{errors.Name}</small>}
                          </div>
                          <div className="mb-2">
                              <label htmlFor="email" className="form-label d-none">Email</label>
                              <input type="text" className="form-control" id="email" name="Email" placeholder="Email" value={formData.Email} onChange={handleChange}/>
                              {errors.Email && <small id="emailHelp" className="form-text text-danger">{errors.Email}</small>}
                          </div>
                          <div className="mb-2">
                              <label htmlFor="phone" className="form-label d-none">Phone</label>
                              <input type="tel" className="form-control" id="phone" name="Phone" placeholder="Phone no."  value={formData.Phone} onChange={handleChange}/>
                              {errors.Phone && <small id="phoneHelp" className="form-text text-danger">{errors.Phone}</small>}
                          </div>
                          <div className="mb-2">
                              <label htmlFor="Message" className="form-label sr-only">Message</label>
                              <textarea name="Message" id="Message" cols="30" rows="5" className="form-control" placeholder="Message" value={formData.Message} onChange={handleChange}></textarea>
                          </div>
                          <div className="col-md-12 my-3" id="captcha-container">
                              <div>
                                  <label htmlFor="captcha-input" className="form-label sr-only">Enter Captcha:</label>
                                  {/* <span id="captchaDisplay" className="captcha-display py-2 mb-1">{captcha}</span> */}
                                  <canvas ref={canvasRef} width={200} height={40}></canvas>
                              </div>
                              <div>
                                <div style={{display: 'flex'}}> 
                                  <input type="text" className="form-control w-50" id="captcha-input" name="captcha" placeholder="Enter Captcha" value={captchaInput}  onChange={handleChange}/>
                                  <button type='button' id="refreshCaptcha" className="btn btn-secondary" onClick={generateCaptcha} style={{marginLeft: '2%', height: '50%'}}>Refresh CAPTCHA</button>
                                  </div>
                                  {errors.captcha && <small id="captchaHelp" className="form-text text-danger py-2">{errors.captcha}</small>}
                              </div>
                          </div>
                          <div className="mb-2 mt-4 text-center">
                              <button type="submit" className="custom-btn" name="submit" value="submit">Submit</button>
                          </div>
                      </form>

                  </div>
              </div>
          </div>
      </div>
  </div>
    </>
  )
}

export default Callform;