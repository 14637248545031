import React from 'react';
import './siteMap.css';
import Banner from '../components/banner';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import SiteMapComponent from '../components/siteMapComponent';

const bannerDetails =
  {
      title: 'Our Sitemap',
      subTitle: 'Stamens Sitemap'
  }

function SiteMap() {
  return (
    <>

<Helmet>
    <title>SiteMap | Stamens Software - A Leading IT Company for Software and Web Solutions</title>
    <meta name="description" content="Explore the sitemap of Stamens Software to easily navigate through our services, solutions, and important pages. Find everything you need about our IT services, development solutions, and more." />
    <meta name="keywords" content="SiteMap, Stamens Software, IT Company, Software Solutions, Web Development, Services Sitemap, Navigation, IT Services, Software Development" />
</Helmet>

    <Banner details={bannerDetails} />

    <SiteMapComponent />

    <Footer />

    </>
  )
}

export default SiteMap;
