import React from "react";
import { Link } from "react-router-dom";
import './about.css';
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import Banner from "../components/banner";
import CardComponent from "../components/cardComponent";
import Consultant from "../components/consultant";
const aboutImage = `${process.env.PUBLIC_URL}/images/about.png`;
const missionImage = `${process.env.PUBLIC_URL}/images/misson.png`;
const visionImage = `${process.env.PUBLIC_URL}/images/vision.png`;
const featureImage1 = `${process.env.PUBLIC_URL}/images/feature_box_1.svg`;
const featureImage2 = `${process.env.PUBLIC_URL}/images/feature_box_2.svg`;
const featureImage3 = `${process.env.PUBLIC_URL}/images/feature_box_3.svg`;

const expertise =[
    {
      image: featureImage1,
      title: 'Development',
      description: 'We develop scalable products with the best design pattern to ensure smooth performance. And our testing team ensures the quality of the product so that the product can perform as expected at peak load time.'
  },
  {
      image: featureImage2,
      title: 'Web Design',
      description: 'We design products that not only look better but also provide a great user experience. Our design team prepares wireframes and visualizations using tools like Adobe Creative Cloud, Figma, and Blender.'
  },
  {
      image: featureImage3,
      title: 'CMS',
      description: 'We create user-specific CMS to optimize the content upload and management process. Our SEO-friendly coding style ensures a search-engine-friendly page with optimized images, links, and meta tags.'
  },
  {
    image: featureImage3,
    title: 'Digital Marketing',
    description: 'Digital marketing encompasses all online strategies and tactics used to promote products or services, reaching audiences through various digital channels such as social media, search engines, email, and websites.'
}
  ]

  const consultant =[
    {
      image: featureImage1,
      title: 'Development',
      description: 'We develop scalable products with the best design pattern to ensure smooth performance. And our testing team ensures the quality of the product so that the product can perform as expected at peak load time.'
  },
  {
      image: featureImage2,
      title: 'Web Design',
      description: 'We design products that not only look better but also provide a great user experience. Our design team prepares wireframes and visualizations using tools like Adobe Creative Cloud, Figma, and Blender.'
  },
  {
      image: featureImage3,
      title: 'CMS',
      description: 'We create user-specific CMS to optimize the content upload and management process. Our SEO-friendly coding style ensures a search-engine-friendly page with optimized images, links, and meta tags.'
  },
  {
    image: featureImage3,
    title: 'Digital Marketing',
    description: 'Digital marketing encompasses all online strategies and tactics used to promote products or services, reaching audiences through various digital channels such as social media, search engines, email, and websites.'
}
  ]

const bannerDetails =
    {
        title: 'About Us',
        subTitle: 'Stamens Software',
        description: 'You might want to include how it started, any key milestones, and what sets you apart from others in your field.'
    }

function  About() {

  return (
    <>
    <Helmet>
    <meta name="description" content="Learn more about Stamens Software, a leading IT company in Noida. We provide cutting-edge software and web development solutions, committed to quality, innovation, and customer satisfaction." />
    <meta name="keywords" content="About Stamens Software, IT Company, Software Development, Web Development, Noida, Our Story, IT Solutions" />
    <title>About Us | Stamens Software - Leading IT Company in Noida</title>
</Helmet>

    <Banner details={bannerDetails} />

        <section className="about-sec">
            <div className="container">
                <div className="about-area">
                    <div className="row">
                        <div className="col-xl-6">
                            <h2 className="head">
                                <span>About Us</span>
                                Innovate business solution for startup companies
                            </h2>
                            <p className="mt-n2 mb-25">At Stamens Software, we specialize in innovative business solutions tailored for startups. Our mission is to help you identify growth opportunities and expand your market presence through cutting-edge strategies and technology.</p>
                            <ul className="check-list">
                                <li><i className="far fa-check-circle"></i> We provide innovative software solutions tailored for startup success.</li>
                                <li><i className="far fa-check-circle"></i> Our expertise helps identify and seize new market opportunities.</li>
                                <li><i className="far fa-check-circle"></i> We focus on optimizing processes to boost operational efficiency and reduce waste.</li>
                                <li><i className="far fa-check-circle"></i> Advanced analytics enable informed decision-making with data-driven insights.</li>
                            </ul>
                            <Link to="/about" className="custom-btn">About Us</Link>
                        </div>
                        <div className="col-xl-6">
                            <div className="img-box">
                                <div className="imgarea">
                                    <img src={aboutImage} alt="About" className="img-fluid" />
                                </div>
                                <div className="experience">
                                    <h2 className="experience-year">
                                            <span className="counter-number">10+</span>
                                    </h2>
                                    <p className="experience-text">Years of experience in startup building</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="misson-vision">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <img src={missionImage} alt="mission" className="img-fluid" />
                        </div>
                        <div className="col-lg-7">
                            <h3 className="head">
                                <span>Our Mission</span>
                                Innovating for Success: Our Technology Mission
                            </h3>
                            <p className="mt-n2 mb-25">Our mission is to empower businesses by delivering innovative and user-centric web development solutions that enhance their online presence. We strive to blend cutting-edge technology with creative design, ensuring that each website we build not only meets our clients' unique needs but also drives engagement and growth in an ever-evolving digital landscape.</p>
                            {/* <p className="mt-n2 mb-25">We are dedicated to creating user-friendly, tailored solutions that seamlessly blend cutting-edge technology with creative design, ensuring that each website not only meets our clients’ unique needs but also captivates and engages their audiences effectively.</p> */}
                        </div>
                    </div>
                </div>
        </section>

        <section className="misson-vision bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <h3 className="head">
                                <span>Our Vision</span>
                                Driving Innovation: Our Vision at Stamens
                            </h3>
                            <p className="mt-n2 mb-25">Our vision is to be a leading web development company recognized for transforming ideas into impactful digital experiences. We aspire to innovate continuously, setting industry standards that inspire businesses to thrive in the digital landscape.</p>
                            <p className="mt-n2 mb-25"></p>
                        </div>
                        <div className="col-lg-5">
                            <img src={visionImage} alt="vision" className="img-fluid rounded" />
                        </div>
                    </div>
                </div>
        </section>

        <section className="expertise">
            <div className="container">
                <h4 className="head text-center">
                    <span>EMBARK ON</span>
                    Our Expertise
                </h4>
                <div className="row gy-4 justify-content-center justify-content-lg-between mt-5">
                {expertise.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentClass='col-lg-3 col-md-6'/>
            ))}
                </div>
            </div>
        </section>

        <Consultant sectionClass={'consultation-about'}/>

        <Footer />
      </>
  );
}

export default About;