import React from 'react';
import './digitalmarketing.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Process from '../components/process';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';
import CircularProgress from '../components/circularprogress';

const apiIntegrationsImg = `${process.env.PUBLIC_URL}/images/icons/api-integrations.png`;

const dmTool1 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-1.png`;
const dmTool2 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-2.png`;
const dmTool3 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-3.png`;
const dmTool4 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-4.png`;
const dmTool5 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-5.png`;
const dmTool6 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-6.png`;
const dmTool7 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-7.png`;
const dmTool8 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-8.png`;
const dmTool9 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-9.png`;
const dmTool10 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-10.png`;
const dmTool11 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-11.png`;
const dmTool12 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-12.png`;

const digitalMarketing = `${process.env.PUBLIC_URL}/images/digital-marketing.png`;
const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const client = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const bannerDetails =
  {
      title: 'Our Services',
      subTitle: 'Digital Marketing',
      description: 'Effective digital marketing drives growth and visibility. Our tailored strategies ensure your brand reaches the right audience at the right time. From SEO to social media management, we focus on delivering measurable results that elevate your online presence and boost engagement.'
  }

  const processCard = [
    {
        spanContent: 'Works',
        heading: 'We are Offering',
        subheading: " Our suite of digital marketing solutions is tailored to your business needs, from comprehensive SEO strategies to seamless API integrations, helping you stay ahead in the digital landscape.",
        processItems: [
            {
                num: '01',
                image: apiIntegrationsImg,
                contentheading: 'SEO',
                description : 'We conduct comprehensive audits, perform keyword research, optimize on-page elements, and create content strategies designed to attract organic traffic.',
                link: '/search-engine-optimization-services-india'
            },
            {
                num: '02',
                image: apiIntegrationsImg,
                contentheading: 'Social Media',
                description : 'Our team helps you craft compelling social media campaigns that build brand awareness, engage with your audience, and drive conversions.',
                link : '/social-media-marketing-india'
            },
            {
              num: '03',
              image: apiIntegrationsImg,
              contentheading: 'Google Ads',
              description : 'From keyword research to ad copywriting and bid management, we optimize your Google Ads campaigns for maximum ROI.',
              link : '/google-ads-services-india'
            }
        ]
    },
    {

    }
]

const Services = [
{
  image : apiIntegrationsImg,
  heading : 'SEO Services'
},
{
  image : apiIntegrationsImg,
  heading : 'PPC Management'
},
{
  image : apiIntegrationsImg,
  heading : 'Web Design & Development'
},
{
  image : apiIntegrationsImg,
  heading : 'Social Media Marketing'
},
{
  image : apiIntegrationsImg,
  heading : 'Content Marketing'
},
{
  image : apiIntegrationsImg,
  heading : 'Paid Social Marketing'
}
]

function Digitalmarketing() {
  return (
    <>

<Helmet>
   <meta name="description" content="Stamens Software, a leading IT company in Noida, provides best digital marketing services in Noida, India. To boost your online presence and drive business growth."/>
   <meta name="keywords" content="Best Digital Marketing Services in Noida, Best Digital Marketing Services in India, Digital Marketing Services"/>
   <title>Best Digital Marketing Services in Noida, India | Stamens Software</title>
</Helmet>


<Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

<section className="startup">
            <div className="container">
                <h2 className="head text-center">No. 1 Social Media Marketing Services in 2024- Preferred by Brands & Startups.</h2>
                <p className="text-center">Today, social media marketing has become one of the best ways to promote your brand and attract potential customers to your business. The main reason behind the success of the platform is its far-reaching scope.</p>
                <p className="text-center">If you haven’t yet tried Facebook, Twitter or Instagram, it’s time to use the platforms as a tool to interact with potential clients before your competitors do. But using a social media marketing strategy in the right way is quite important to gain customers. Choosing the right marketing strategy will assist you in defining the success rate of your business.</p>
                <p className="text-center">Remember, if a specific social media marketing strategy works on one business, that doesn’t mean it will work for you too. This is where Stamens Software – A leading IT Company, comes with the perfect ROI driven digital marketing strategy as well. We can help you with customized social media strategies to ensure that your business reaches sky-high heights of success.</p>
            </div>
        </section>

{processCard.map((item, index) => (
        <Process 
          key={index}
          spanContent={item.spanContent}
          heading={item.heading}
          subheading={item.subheading}
          processItems={item.processItems}
          parentClass={"digital-process-dm"}
        />
      ))}

<section className="digital-service bg-white">
            <div className="container">
                <h3 className="head text-center">
                    Boost Sales With Our Digital Marketing Service
                </h3>
                <p className="text-center fs-5 pb-4"> From search engine optimization (SEO) to targeted social media campaigns and high-converting paid ads, our approach is designed to amplify your sales performance.</p>
                <div className="row justify-content-center gy-4">
                {Services.map((card, index) => (
                  <ServiceComponent key={index} serviceprops={card} h4={'h4'} />
                ))}
                </div>
            </div>
        </section>

  <LetsCode />

  <section className="our-fetaures-dm">
    <div className="container">
        <h3 className="head text-center"> Transform Your Brand's Online Presence With Digital Marketing Service </h3>
        <p className="text-center fs-5 pb-4">Our expert team utilizes a blend of SEO, social media marketing, content creation, paid advertising, and data-driven insights to boost your visibility, grow your online presence, and increase conversions.</p>

        <div className="row justify-content-center gy-4">
            <div className="col-lg-6">
                <div className="part">
                    <CircularProgress percent={76} />
                    <div className="content">
                        <h4 className="small-head">Brand Awareness</h4>
                        <p>It’s the right time to create brand awareness, traffic and conversions. The social media page present on your website will help you either gain customers or lose them.</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="part">
                    <CircularProgress percent={88} />
                    <div className="content">
                        <h4 className="small-head">Lead Generation</h4>
                        <p>For any business that wishes to stand out from the crowd, it's important to improve leads and conversions. Generating leads isn't as easy as it seems. </p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="part">
                    <CircularProgress percent={73} />
                    <div className="content">
                        <h4 className="small-head">Traffic Boost</h4>
                        <p>Boosting traffic for your website is quite important, and this is near to impossible if you don't use the right social media marketing platforms and right strategy. </p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="part">
                    <CircularProgress percent={68} />
                    <div className="content">
                        <h4 className="small-head">Social Media Engagement</h4>
                        <p>Social media marketing is like having a goldmine to improve your business and generate effective results. The reason is that millions of new users join the social media platform every day. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


        <section className="technologie-dm">
            <div className="container">
                <h3 className="head">
                    <span>Our Technologies</span>
                    All Technologies Software in stamens
                </h3>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool1} alt="tool icon 1" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool2} alt="tool icon 2" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool3} alt="tool icon 3" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool4} alt="tool icon 4" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool5} alt="tool icon 5" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool6} alt="tool icon 6" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool7} alt="tool icon 7" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool8} alt="tool icon 8" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool9} alt="tool icon 9" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool10} alt="tool icon 10" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool11} alt="tool icon 11" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={dmTool12} alt="tool icon 12" className="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="partner bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pt-4">
                            <img src={digitalMarketing} alt="vision" className="img-fluid rounded" style={{height: '100%', objectFit: 'cover'}} />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="head">
                            Looking to elevate your online presence and drive real results
                            </h4>
                            <p className="mt-n2 mb-25">Looking to elevate your online presence and drive real results? Our team of digital marketing experts is here to help you succeed.</p>
                            <p className="mt-n2 mb-25">We specialize in crafting tailored marketing strategies that leverage the latest trends and technologies to boost visibility, increase engagement, and generate leads for your business.</p>
                            <a href="#" className="hire-btn">Hire Us Now</a>
                        </div>
                    </div>
                </div>
        </section>


        <section className="our-company">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={client} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>     

    <Footer />
    </>
  )
}

export default Digitalmarketing;
