import React from 'react'
import Footer from '../components/footer';
import './uiux.css';
import { Link } from 'react-router-dom';
import LetsCode from '../components/letscode';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
const uiux = `${process.env.PUBLIC_URL}/images/ui-ux.png`;

const angular = `${process.env.PUBLIC_URL}/images/tools/angular.svg`;
const bootstrap = `${process.env.PUBLIC_URL}/images/tools/bootstrap.svg`;
const chatGpt = `${process.env.PUBLIC_URL}/images/tools/chatGpt.svg`;
const css = `${process.env.PUBLIC_URL}/images/tools/css.svg`;
const figma = `${process.env.PUBLIC_URL}/images/tools/figma.svg`;
const html = `${process.env.PUBLIC_URL}/images/tools/html.svg`;
const illustrator = `${process.env.PUBLIC_URL}/images/tools/illustrator.svg`;
const javaScript = `${process.env.PUBLIC_URL}/images/tools/javaScript.svg`;
const materialUi = `${process.env.PUBLIC_URL}/images/tools/material-ui.svg`;
const photoshop = `${process.env.PUBLIC_URL}/images/tools/photoshop.svg`;
const reactJs = `${process.env.PUBLIC_URL}/images/tools/reactJs.svg`;
const typeScript = `${process.env.PUBLIC_URL}/images/tools/typeScript.svg`;
const vueJs = `${process.env.PUBLIC_URL}/images/tools/VueJs.svg`;
const xd = `${process.env.PUBLIC_URL}/images/tools/xd.svg`;
const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const client = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;

const bannerDetails =
    {
        title: 'Our Services',
        subTitle: 'UX/UI Design',
        description: 'We build custom APIs for all breeds of applications, adding functionality to your software systems and facilitating communication between your apps & others.'
    }

function UiUx() {
  return (
    <>

<Helmet>
<meta name="description" content="Stamens Software, a top IT company in Noida, specializes in UI/UX design and development Services, creating intuitive, engaging interfaces for enhanced user experiences."/>
<meta name="keywords" content="UI/UX Design and Development Services in Noida, UI/UX Design Services in Noida, User Interface Development in Nodia"/>
    <title>Best UI/UX Design Company in Noida | Designing Services</title>
    </Helmet>

    <Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

        <section className="deliverable bg-white">
            <div className="container">
                <h2 className="head text-center">
                    Our UI/UX Deliverables
                </h2>
                <p className="text-center fs-5 pb-4">We Turn Strategy into Design Solutions for Fast-Growing Companies</p>
                <div className="center-sec">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7">
                            <h3 className="head">UI/UX Design</h3>
                            <p>UI/UX design is all about creating websites and apps that look great and are super easy to use. It includes designing the layout, buttons, and overall feel to make sure users have the best experience possible.</p>
                            <div className="row justify-content-center gy-4">
                                <div className="col-lg-4">
                                    <div className="part">
                                        <p className="name">UI/UX Audit & Usability Testing</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="part">
                                        <p className="name">Wireframing & Rapid Prototyping</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="part">
                                        <p className="name">UI Design for Website & Mobile App</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="part">
                                        <p className="name">Brand & Style Guides</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="part">
                                        <p className="name">Visual Design & Illustrations</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="part">
                                        <p className="name">Animation & Motion Graphics</p>
                                    </div>
                                </div>
                            </div>
                            <a href="#" className="requirements">DISCUSS YOUR REQUIREMENTS</a>
                        </div>
                        <div className="col-lg-5">
                            <img src={uiux} alt="ui ux" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="technologies-uiux">
            <div className="container">
                <h3 className="head text-center">
                    Technologies We Use
                </h3>
                <p className="text-center fs-5 pb-4">Exploring the Tools that Shape Our Design Process</p>
                <div className="tools">
                    <div className="tool">
                        <div className="image">
                            <img src={html} alt="html" className="img-fluid" />
                        </div>
                        <p>HTML</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={css} alt="css" className="img-fluid" />
                        </div>
                        <p>CSS</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={javaScript} alt="javascript" className="img-fluid" />
                        </div>
                        <p>javascript</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={typeScript} alt="Typescript" className="img-fluid" />
                        </div>
                        <p>Typescript</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={reactJs} alt="React js" className="img-fluid" />
                        </div>
                        <p>React.js</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={angular} alt="Angular" className="img-fluid" />
                        </div>
                        <p>Angular</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={vueJs} alt="Vue js" className="img-fluid" />
                        </div>
                        <p>Vue.js</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={materialUi} alt="Material-UI" className="img-fluid" />
                        </div>
                        <p>Material-UI</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={bootstrap} alt="Bootstrap" className="img-fluid" />
                        </div>
                        <p>Bootstrap</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={chatGpt} alt="ChatGTP4" className="img-fluid" />
                        </div>
                        <p>ChatGTP4</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={figma} alt="Figma" className="img-fluid" />
                        </div>
                        <p>Figma</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={xd} alt="XD" className="img-fluid" />
                        </div>
                        <p>XD</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={photoshop} alt="Photoshop" className="img-fluid" />
                        </div>
                        <p>Photoshop</p>
                    </div>
                    <div className="tool">
                        <div className="image">
                            <img src={illustrator} alt="Illustrator" className="img-fluid" />
                        </div>
                        <p>Illustrator</p>
                    </div>
                </div>
            </div>
        </section>

       <LetsCode />

        <section className="process">
            <div className="container">
                <h3 className="head text-center">
                    Our UI/UX Design Process
                </h3>
                <p className="text-center fs-5 pb-4">Discover our UI/UX process, combining modern design with user-centric
                    strategies to elevate your business.</p>
                
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">Strategy</h4>
                            <p>Collaborate, inspire new ideas, focus on user needs, and achieve shared goals.</p>
                            <ul>
                                <li>Design Workshops</li>
                                <li>User Journeys & Personas</li>
                                <li>User Studies & Research</li>
                                <li>Competitor Analysis</li>
                                <li>UX Audits</li>
                                <li>Roadmapping</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">Visualisation</h4>
                            <p>Enhance customer experience is enhanced by turning complex problems into simple, elegant designs.</p>
                            <ul>
                                <li>UX/UI Design</li>
                                <li>Website Design</li>
                                <li>Illustration & Icons</li>
                                <li>Animation & Video</li>
                                <li>Branding & Logos</li>
                                <li>Marketing Collateral</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="part">
                            <h4 className="head">Implementation</h4>
                            <p>Effortlessly scale with Systemise, ensuring pixel-perfect design and fully documented front-end code.</p>
                            <ul>
                                <li>Design Systems</li>
                                <li>UI Kit Development</li>
                                <li>Frontend Implementation</li>
                                <li>Dev Documentation</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    <a href="#" className="requirements">DISCUSS YOUR REQUIREMENTS</a>
                </div>

            </div>
        </section>

        <section className="partner bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pt-4">
                            <img src={uiux} alt="ui ux" className="img-fluid" />
                        </div>
                        <div className="col-lg-7">
                            <h4 className="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p className="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p className="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <Link to="/contact" className="hire-btn">Hire Us Now</Link>
                        </div>
                    </div>
                </div>
           
        </section>

        <section className="our-company-uiux">
            <div className="container">
                <h4 className="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    <Footer />
    </>
  )
}

export default UiUx;

