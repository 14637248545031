import React from 'react';
import './servicePage.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import IconCard from '../components/iconCard';
import LetsCode from '../components/letscode';
import Serviceform from '../components/serviceform';
const WebDevelopment = `${process.env.PUBLIC_URL}/images/web-development.png`;
const Customsoftwaredevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;

const industry15 = `${process.env.PUBLIC_URL}/images/icons/15-years-in-industry.png`;
const sourceCode = `${process.env.PUBLIC_URL}/images/icons/source-code-delivery-to-clients.png`;
const microsoftAzure = `${process.env.PUBLIC_URL}/images/icons/microsoft-365-azure.png`;
const response24 = `${process.env.PUBLIC_URL}/images/icons/less-than-24-hrs-guaranteed-response.png`;
const certifiedDevelopers = `${process.env.PUBLIC_URL}/images/icons/certified-developers.png`;
const projectManager = `${process.env.PUBLIC_URL}/images/icons/dedicated-project-manager.png`;
const client = `${process.env.PUBLIC_URL}/images/icons/clients-usa-uk-australia.png`;
const tightDeadlines = `${process.env.PUBLIC_URL}/images/icons/handle-tight-deadlines.png`;


const webDevelopmentCard = [
    {
        image: Customsoftwaredevelopment,
        title: 'Frontend Development',
        description: 'We specializes in frontend development, creating responsive, engaging websites that elevate user experience.',
        button: true,
        to : '/'
      },
      {
        image: Customsoftwaredevelopment,
        title: 'Backend Development',
        description: 'We excels in backend development, building robust, scalable solutions that power your web applications.',
        button: true,
        to : '/'
      },
      {
        image: Customsoftwaredevelopment,
        title: 'Full Stack Development',
        description: 'We offers full-stack development, integrating frontend and backend solutions for seamless and high-performance.',
        button: true,
        to : '/'
      },
      {
        image: Customsoftwaredevelopment,
        title: 'Cloud Development',
        description: "We specializes in cloud development, delivering scalable and secure solutions that enhance your business's agility and performance.",
        button: true,
        to : '/'
      }
]

const Technologies = [
    {
        title: 'React JS',
        description: 'React is widely used for building applications, ranging from simple single-page apps to complex, feature-rich platforms.',
        button: true,
        to : '/'
      },
      {
        title: '.Net',
        description: '.NET is popular for dynamic, data-driven web applications due to its performance and support for C#.',
        button: true,
        to : '/'
      },
      {
        title: 'Angular JS',
        description: 'AngularJS is primarily used for developing single-page applications (SPAs) where most of the functionality is handled on the client side.',
        button: true,
        to : '/'
      },
      {
        title: 'Java',
        description: "Java is popular in the development of financial and trading systems due to its performance, security, and reliability.",
        button: true,
        to : '/'
      }
      ,
      {
        title: 'Node JS',
        description: "A versatile platform for delivering various services, leveraging its event-driven, non-blocking I/O architecture.",
        button: true,
        to : '/'
      },
      {
        title: 'Python',
        description: "Python is popular for machine learning and artificial intelligence due to its simplicity and powerful libraries.",
        button: true,
        to : '/'
      }
]

const bannerDetails =
    {
        title: 'Our Services',
        subTitle: 'Web Application Development',
        description: 'We create robust, scalable web applications tailored to your business needs, ensuring seamless user experiences and high performance.'
    }

function WebDevelopmentService() {

  return (
    <>
    <Helmet>
    <meta name="description" content="Stamens Software, a leading IT company in Noida, delivers top-notch web development services, crafting responsive, secure, and user-friendly websites."/>
<meta name="keywords" content="Web Development Services in Noida, Web Development company, Web Application Development in Noida"/>
    <title>Web Development Services | Web Development company - Stamens Software</title>
    </Helmet>

    <Banner details={bannerDetails} />

        <section className="banner-bottom-part">
            <div className="container">
                <div className="row justify-content-center gy-4">
                {webDevelopmentCard.map((card, index) => (
                <IconCard key={index} cardProps={card} parentClass={'col-lg-3 col-md-6'} childClassA={'rotate'}/>
            ))}
                </div>
            </div>
        </section>

        <section className="work-process">
            <div className="container">
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-6 d-flex align-items-center">
                        <img src={WebDevelopment} alt="web Development" className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                        <h2 className="head"><span>work process</span> From Concept to Completion</h2>
                        <p>With approved designs in hand, our skilled developers bring your vision to life. We utilize the latest technologies and best practices to build a robust and scalable solution, ensuring seamless integration between frontend and backend components.</p>
                        <div className="points">
                            <div className="part">
                                <div className="icon">
                                    <i className="bi bi-file-earmark-text"></i>
                                </div>
                                <div className="content">
                                    <h3 className="head">Purpose and Usage</h3>
                                    <p>Our structured work process enhances efficiency and collaboration, ensuring your vision is realized effectively and resulting in a high-quality final product.</p>
                                </div>
                            </div>
                            <div className="part">
                                <div className="icon">
                                    <i className="bi bi-file-earmark-text"></i>
                                </div>
                                <div className="content">
                                    <h3 className="head">Purpose and Usage</h3>
                                    <p>Our streamlined work process promotes clear communication and efficiency, allowing us to transform your ideas into a polished final product that meets your expectations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="technologies">
            <div className="container">
                <h3 className="head"><span>Our Technologies</span>
                Keys To Our Digital Strategy
                </h3>
                <div className="row justify-content-center gy-4">
                {Technologies.map((card, index) => (
                <IconCard key={index} cardProps={card} parentClass={'col-lg-4 col-md-6'} iconClass={'bi bi-file-earmark-text'} childClassA={'icon'} childClassB={'content'}/>
            ))}
                </div>
            </div>
        </section>
 
        <section className="work-steps">
            <div className="container">
                <h4 className="head"><span>Work Process</span>Working Process For Technology</h4>
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Planning and Research</p>
                                <p>Start with research and planning to define goals and audience.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Design</p>
                                <p>Create wireframes and prototypes based on user needs and project goals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Development</p>
                                <p>
                                Translate designs into code, ensuring functionality and responsiveness.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Testing and Deployment</p>
                                <p>Conduct thorough testing and deploy the site for public access.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <LetsCode />

        <section className="our-company-sec">
            <div className="container">
                <h4 className="head"><span>Why Stamens Software</span>We are one of the best IT companies?</h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={industry15} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={sourceCode} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={microsoftAzure} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={response24} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={certifiedDevelopers} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={projectManager} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={client} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={tightDeadlines} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Serviceform serviceformWeb={'serviceformWeb'}/>

        <Footer />

    </>
  )
}

export default WebDevelopmentService;