import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
const stamenslogo = `${process.env.PUBLIC_URL}/images/logo.svg`;
const deg = `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`;
const cabi = `${process.env.PUBLIC_URL}/images/clients/cabi.png`;
const scadea = `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`;
const techolution = `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`;
const proIt = `${process.env.PUBLIC_URL}/images/clients/pro-it.png`;
const nextLoop = `${process.env.PUBLIC_URL}/images/clients/next-loop.png`;


function Footer() {

    const [Email, setEmail] = useState();
    const [error, setError] = useState();
    const formRef = useRef();

    const URL = `${process.env.REACT_APP_CONT_URL}` + '/api/Subscribe'
    const checkURL = `${process.env.REACT_APP_CONT_URL}` + '/api/Subscribe/check';

    const handleChange = (e) => {
        setEmail(e.target.value)
    }


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubscribe = async () => {

        if (!Email) {
            setError('Email is required');
            return;
        }

        if (!validateEmail(Email)) {
            setError('Invalid email address');
            return;
        }

        try {
            const checkResponse = await fetch(`${checkURL}/${encodeURIComponent(Email)}`);
            const isExisting = await checkResponse.json();

            if (isExisting) {
                setError('Email is already subscribed.');
                return;
            }

            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: Email }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.message || 'An error occurred');
                return;
            }

            const result = await response.json();
            setEmail('');
            setError('');

        } catch (error) {
            setError('An unexpected error occurred');
            setEmail('');
        }
    };


    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="footer-left">
                            <img src={stamenslogo} alt="footer Logo" className="img-fluid" height="53px" width="124px" />
                            <p className="para">At Stamens Software our main focus is on delivering quality products in the least possible time at a very marginal cost. We believe in continuous improvement and client satisfaction. Let's work together and experience the difference that passion, expertise, and dedication make in transforming your ideas into reality.</p>
                            <div className="footer-address mt-3">
                                <p className="no-margin text-white pl-2 d-flex gap-2"><div className="icon">
                                    <i className="fa fa-location-dot"></i>
                                </div><span> B-33, 2nd Floor, Sector-6, Noida, Uttar Pradesh, 201301, India</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3 className="footer-heading mb-3">Resources</h3>
                                <ul className="ft_res_first mb-0">
                                    <li><Link to='/' >Home</Link></li>
                                    <li><Link to='/about' >About Us</Link></li>
                                    <li><Link to="/" >Blog</Link></li>
                                    <li><Link to="/" >FAQ</Link></li>
                                    <li><Link to="/" >Our Partner</Link></li>
                                    <li><Link to="/" >News</Link></li>
                                    <li><Link to="/ourteam" >Team</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="footer-heading mb-3">Useful Link</h3>
                                <ul className="ft_res_first mb-0">
                                    <li><Link data-bs-toggle="modal" data-bs-target="#exampleModal">Work With Us</Link></li>
                                    <li><Link to="/career" >Career</Link></li>
                                    <li><Link to="/" >Help & Support</Link></li>
                                    <li><Link to="/on-demand-developer">Hire Talent</Link></li>
                                    <li><Link to="/" >Location</Link></li>
                                    <li><a href="/pdfs/religious policy at our company.pdf" target="_blank" rel="noopener noreferrer">Religious Policy</a></li>
                                    <li><a href="/pdfs/Gender Pay Gap Report.pdf" target="_blank" rel="noopener noreferrer">Gender Pay Gap</a></li>
                                    <li><a href="/pdfs/Environmental Policy Statement.pdf" target="_blank" rel="noopener noreferrer">Environment Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="footer-client">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="footer-heading mb-3">Our Influential Clients</h3>
                                    <p className="footer-para">Providing services to our prestigious clients around the world.</p>
                                </div>
                                <div className="col-md-12 text-center">
                                    <div className="footer-brands">
                                        <img src={deg} alt="66deg" className="img-fluid" height="103" width="237" />
                                        <img src={cabi} alt="cabi" className="img-fluid" height="103" width="237" />
                                        <img src={techolution} alt="techolution" className="img-fluid" height="103" width="237" />
                                        <img src={scadea} alt="scadea" className="img-fluid" height="103" width="237" />
                                        <img src={proIt} alt="rm" className="img-fluid" height="103" width="237" />
                                        <img src={nextLoop} alt="next-loop" className="img-fluid" height="103" width="237" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-between news-social-sec"><div class="col-lg-4"><div class="social-container ps-4"><h3 class="footer-heading mb-3">Follow us on</h3><ul class="social-icons p-0"><li><a href="https://www.facebook.com/stamenssoftware/" aria-label="Social Media"><i class="fa-brands fa-facebook-f"></i></a></li><li><a href="https://www.instagram.com/stamens.software/" aria-label="Social Media"><i class="fa-brands fa-instagram"></i></a></li><li><a href="https://twitter.com/StamensSoftware" aria-label="Social Media"><i class="fa-brands fa-x-twitter"></i></a></li><li><a href="https://www.linkedin.com/company/stamenssoftware/mycompany/" aria-label="Social Media"><i class="fa-brands fa-linkedin-in"></i></a></li><li><a href="https://www.youtube.com/" aria-label="Social Media"><i class="fa-brands fa-youtube"></i></a></li></ul></div></div><div class="col-lg-4"><div class="call_us_footer"><h3 class="footer-heading mb-3">Email Us</h3><p class="mb-0"><i class="fa fa-envelope" style={{ marginRight: '2%' }}></i><a href="mailto:contact@stamenssoftware.com" class="text-white text-decoration-none">contact@stamenssoftware.com</a></p></div></div><div class="col-lg-4"><div class="call_us_footer"><h3 class="footer-heading mb-3">Get in Touch</h3><p class="mb-0"><i class="fa fa-phone" style={{ marginRight: '2%' }}></i><a href="tel:+911203500847" class="text-decoration-none text-white">+91 120 3500 847</a><a href="tel:+911203500848" class="text-decoration-none text-white">, 120 3500 848</a></p></div></div></div>

                <div className="copyright">
                    <div className="d-flex align-items-center flex-wrap gap-3">
                        <Link to="/privacypolicy">Privacy Policy</Link>
                        <Link to="/legalnotice">Legal Notice</Link>
                        <Link to="/termsandconditions">Terms and Conditions</Link>
                    </div>
                    <p className="text-center">© Copyrights 2025 by Stamens Software Private Limited - All Rights Reserved</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;